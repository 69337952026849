import { useMutation, useQuery } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { getAPIAccessToken } from '../lib/auth0'
import { apiClients } from '../lib/apiClient'

/**
 * 全商品を取得するＡＰＩをbackendにリクエスト
 * 例: /api/products
 */
export const useGetProductsQuery = () => {
  return useQuery({
    queryKey: ['products'],
    queryFn: async () => {
      const res = await apiClients['/api/products'].GET.client({
        query: {}
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 * 個別の商品を取得するＡＰＩをbackendにリクエスト
 * 例: api/products/water
 */
export const useGetProductDetailsBySlugQuery = (slug: string) => {
  return useQuery({
    queryKey: ['products', slug],
    queryFn: async () => {
      const res = await apiClients['/api/products/slug/:slug'].GET.client({
        params: { slug }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 * 個別の商品を取得するＡＰＩをbackendにリクエスト
 * 例: get api/products/65711ec5d4094433e6ae830e
 */
export const useGetProductDetailsByIdQuery = (id: string) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: ['products', id],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/products/:id'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { id }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 * 個別の商品を更新するＡＰＩをbackendにリクエスト
 * 例: api/products/65711ec5d4094433e6ae830e
 */
type UpdateProductClient = (typeof apiClients)['/api/admin/products/:id']['POST']['client']

export const usePostProductDetailsByIdQuery = (id: string) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation({
    mutationFn: async (body: Parameters<UpdateProductClient>[0]['body']) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/products/:id'].POST.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { id },
        body
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 * 全カテゴリを取得するAPI
 * 例: api/products/categories
 */
export const useGetCategoriesQuery = () => {
  return useQuery({
    queryKey: ['categories'],
    queryFn: async () => {
      const res = await apiClients['/api/products/categories'].GET.client({})

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/*
 * パラメータで商品検索APIをBackendにリクエスト
 * 例: api/search/query=${query}&category=${category}
 */
export const useGetProductsbyMultipleQueries = (queries: {
  query?: string
  category?: string
  price?: string
  page?: number
  order?: string
}) => {
  return useQuery({
    queryKey: ['products', queries.query, queries.category, queries.price, queries.page, queries.order],
    queryFn: async () => {
      const res = await apiClients['/api/search'].GET.client({
        query: queries
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 *
 * 例: api/products/admin?page=1
 */
export const useGetProductsListQuery = (page: number) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: ['products', page],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/products'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        query: { page }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 *
 * 例: api/admin/products
 */
export const usePostCreateProductsQuery = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation({
    mutationFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/products'].POST.client({
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}
