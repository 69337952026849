import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { getAPIAccessToken } from '../lib/auth0'
import { apiClients } from '../lib/apiClient'

/**
 *
 * 例: post api/cart
 */
type AddCartClient = (typeof apiClients)['/api/cart']['POST']['client']

export const useAddCartMutation = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (cart: Parameters<AddCartClient>[0]['body']) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/cart'].POST.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: cart
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cart'] })
    }
  })
}

/**
 *
 * 例: api/cart/mine/
 */
export const useGetCartQuery = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: ['cart'],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/cart'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 *
 * 例: delete /api/cart/item/:id
 */
type DeleteCartClient = (typeof apiClients)['/api/cart/item/:id']['DELETE']['client']

export const useRemoveCartItemMutation = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const queryClient = useQueryClient()

  return useMutation<
    Awaited<ReturnType<DeleteCartClient>>['body'],
    { message: string },
    Parameters<DeleteCartClient>[0]['params']
  >({
    mutationFn: async (args) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/cart/item/:id'].DELETE.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { id: args.id }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cart'] })
    }
  })
}

/**
 *
 * 例: api/cart/batch/
 */
type BatchClient = (typeof apiClients)['/api/cart/batch']['POST']['client']

export const useAddBatchCartMutation = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (body: Parameters<BatchClient>[0]['body']) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/cart/batch'].POST.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        body
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['cart'] })
    }
  })
}
