import { Box, Container, CssBaseline, Stack, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { PageTitle, SectionTitle } from '../components/typography'
import { InputTitle } from '../components/form'
import { Link } from 'react-router-dom'

const Page = () => {
  return (
    <Box sx={{ background: '#FFFCF8' }}>
      <Helmet>
        <title>Visnu EC - Commerce Disclosure</title>
      </Helmet>
      <CssBaseline />
      <Container maxWidth="md" component={'main'} sx={{ py: 20 }}>
        <Stack gap={6} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <PageTitle title="特定商取引法に基づく表記" subtitle="Commerce Disclosure" />

          <Stack sx={{ width: '100%' }} gap={3}>
            <SectionTitle>サービス情報</SectionTitle>

            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>サービス名</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                Visnu EC
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>販売事業者名</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                Visnu株式会社
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>代表者名</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                代表取締役 千葉 涼介
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>所在地</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                〒020-0024
                <br />
                岩手県盛岡市菜園1-3-6 農林会館8F
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>お問い合わせ</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                こちらの<Link to={'/contact'}>お問合せフォーム</Link>からお問い合わせできます。
                <br />
                お問合せ電話番号：050-5476-4378
                <br />
                お問合せメール：ec-contact@visnu.jp
              </Typography>
            </Stack>
          </Stack>

          <Stack sx={{ width: '100%' }} gap={3}>
            <SectionTitle>支払い情報</SectionTitle>

            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>販売価格</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                購入手続きの際に画面に表示されます。消費税は内税として表示しております。
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>販売価格以外でお客様に発生する金銭</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                ※当サイトのページの閲覧、購入手続き等に必要となるインターネット接続料金、通信料金は、お客様のご負担となります。
              </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>お支払方法</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                以下のいずれかのお支払方法をご利用いただけます。
                <br />
                ・各種クレジットカード
                <br />
                ・銀行振込
              </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>決済期間</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                クレジットカード決済の場合はただちに処理されますが、国内の銀行振込の場合は注文から 3
                日以内にお振り込みいただく必要があります。
              </Typography>
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>商品購入方法</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                こちら (https://ec.visnu.io/)
                から「カートに追加」ボタンを押下し、「会員登録」して、各種お支払方法で決済していただきますと、利用可能となります。
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>商品の利用が可能となる時期及び引き渡し時期</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                お支払い手続き完了後直ちにご利用いただけます。
                <br />
                商品はご注文から7日以内に発送します。
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>返品・キャンセル</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                ご購入後の返金・返品はできかねます。あらかじめ料金プランをよくお確かめの上、お申込み、もしくはご購入願います。
                <br />
                販売時点において商品の種類または品質に関して契約の内容に適合しない欠陥がある場合において受領後7日以内のみ交換が可能ですのでご連絡ください。
              </Typography>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
              <InputTitle width={200}>特別条件</InputTitle>
              <Typography fontSize={16} fontWeight={600}>
                １．クーリングオフについて
                <br />
                特定商取引法に規定されているクーリングオフが適用されるサービスではありません。
                <br />
                ２．定期課金方式の注意事項
                <br />
                契約期間途中の解約となった場合も契約満了日までの料金が発生し、日割精算等による返金を含めた一切の返金は行われません。この場合、サービスも契約満了日まで提供されます。
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

export default Page
