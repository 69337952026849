import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { getAPIAccessToken } from '../lib/auth0'
import { apiClients } from '../lib/apiClient'

type GetAccessTokenSilentlyType = Parameters<typeof getAPIAccessToken>[0]
type GetAccessTokenWithPopupType = Parameters<typeof getAPIAccessToken>[1]

export const userInfoQueryKey = 'userInfo'

export const useUserInfo = (
  getAccessTokenSilently: GetAccessTokenSilentlyType,
  getAccessTokenWithPopup: GetAccessTokenWithPopupType
) => {
  return useQuery({
    queryKey: [userInfoQueryKey],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/user/info'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

type UpdateClient = (typeof apiClients)['/api/user/info/update']['POST']['client']
type UpdateRequestSchema = Parameters<UpdateClient>[0]['body']
type UpdateResponseSchema = Awaited<ReturnType<UpdateClient>>['body']

export const useUpdateUserInfo = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const queryClient = useQueryClient()

  return useMutation<UpdateResponseSchema, { message: string }, UpdateRequestSchema>({
    mutationFn: async (args) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/user/info/update'].POST.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: args
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [userInfoQueryKey] })
    }
  })
}
