import { useMutation, useQuery } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { getAPIAccessToken } from '../lib/auth0'
import { apiClients } from '../lib/apiClient'

/**
 * get all categories
 * 例: api/categories/all
 */
export const useGetAllCategories = (page: number) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: ['all-categories', page],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/categories/all'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        query: { page }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 * get all categories
 * 例: api/categories/allupaged
 */
export const useGetAllCategoriesUnpaged = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: ['all-categories-upaged'],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/categories/allupaged'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 * カテゴリ削除
 * 例: api/categories/delete/
 */
export const useDeleteCategoriesById = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation({
    mutationFn: async (category: { id: string }) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/categories/:id'].DELETE.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          id: category.id
        }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 *
 * 例: api/categories/id/65711ec5d4094433e6ae830e
 */
export const useGetCategoryByIdQuery = (id: string) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: ['categories', id],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/categories/:id'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { id }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 * 個別のユーザを更新するＡＰＩをbackendにリクエスト
 * 例: api/categories/id/65711ec5d4094433e6ae830e
 */
export const usePostCategoryByIdQuery = (id: string) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation({
    mutationFn: async (category: { label: string; jpLabel: string }) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/categories/:id'].PUT.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { id },
        body: category
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 *
 * 例: api/categories
 */
export const usePostCreateCategoryQuery = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation({
    mutationFn: async (category: { label: string; jpLabel: string }) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/categories'].POST.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: category
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}
