import { Box, Container, CssBaseline, Stack, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { RoundedButton } from '../components/button'
import { Footer, Header, SideMenu, UserInfo } from '../components/common'

const Page = () => {
  return (
    <Box sx={{ background: '#FFFCF8' }}>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <CssBaseline />
      <Header />
      <Container maxWidth="md" component={'main'} sx={{ py: 20 }}>
        <Stack alignItems={'center'} gap={8}>
          <Stack alignItems={'center'}>
            <Typography fontSize={180} fontWeight={600}>
              404
            </Typography>
            <Typography fontSize={24}>Page not found.</Typography>
            <Typography fontSize={40}>ページが見つかりませんでした。</Typography>
          </Stack>
          <RoundedButton component={Link} to={'/'} color="gray">
            トップに戻る
          </RoundedButton>
        </Stack>
      </Container>
      <SideMenu />
      <UserInfo />
      <Footer />
    </Box>
  )
}

export default Page
