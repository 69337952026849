import { useAuth0 } from '@auth0/auth0-react'
import { useQuery } from '@tanstack/react-query'
import { getAPIAccessToken } from '../lib/auth0'
import { apiClients } from '../lib/apiClient'

export const useBcpConnectedOrgPlaces = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: ['bcp-organization-connected-places'],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/bcp/organization/connected/places'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

export const useBcpOrganizations = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: ['bcp-organization-list'],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/bcp/organization/list'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}
