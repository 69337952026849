import React from 'react'
import { CartItem } from './types/Cart'
import { ShippingAddress } from './types/ShippingInfo'

type AppState = {
  cart: {
    cartItems: CartItem[]
  }
  userInfo: { _id: string; email: string } | null
}

//カートの初期状態
const initialState: AppState = {
  //ユーザの初期状態
  userInfo: null,
  //カートの初期状態
  cart: {
    cartItems: localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : []
  }
}

// actionタイプを定義
type Action =
  | { type: 'CART_ADD_ITEM'; payload: CartItem }
  | { type: 'CART_REMOVE_ITEM'; payload: CartItem }
  | { type: 'USER_SIGNIN'; payload: NonNullable<AppState['userInfo']> }
  | { type: 'USER_SIGNOUT' }
  | { type: 'SAVE_SHIPPING_ADDRESS'; payload: ShippingAddress }
  | { type: 'CART_CLEAR' }

//Reduxのstateを変更する処理
export function reducer(state: AppState, action: Action): AppState {
  switch (action.type) {
    case 'CART_ADD_ITEM': {
      const newItem = action.payload
      //カートの既存アイテムがあるか確認
      const existItem = state.cart.cartItems.find((item: CartItem) => item.id === newItem.id)
      // 商品がもうカートにあるなら、数量を更新する
      const cartItems = existItem
        ? state.cart.cartItems.map((item: CartItem) => (item.id === existItem.id ? newItem : item))
        : [...state.cart.cartItems, newItem]
      // カートを更新
      localStorage.setItem('cartItems', JSON.stringify(cartItems))
      return { ...state, cart: { ...state.cart, cartItems } }
    }
    //カートから商品削除
    case 'CART_REMOVE_ITEM': {
      const cartItems = state.cart.cartItems.filter((item: CartItem) => item.id !== action.payload.id)
      localStorage.setItem('cartItems', JSON.stringify(cartItems))
      return { ...state, cart: { ...state.cart, cartItems } }
    }
    //ログイン
    case 'USER_SIGNIN':
      return { ...state, userInfo: action.payload }
    //ログアウト
    case 'USER_SIGNOUT':
      // TODO DBに保存する
      localStorage.removeItem('cartItems')
      localStorage.removeItem('shippingAddress')
      localStorage.removeItem('paymentMethod')
      return {
        userInfo: null,
        cart: {
          cartItems: []
        }
      }
    //配送先住所保存
    case 'SAVE_SHIPPING_ADDRESS':
      localStorage.setItem('shippingAddress', JSON.stringify(action.payload))
      return {
        ...state,
        cart: {
          ...state.cart
        }
      }
    default: {
      return state
    }
  }
}

//初期状態を設定
const defaultDispatch: React.Dispatch<Action> = () => initialState
export const Store = React.createContext({
  state: initialState,
  dispatch: defaultDispatch
})

//アプリにwrapできるProvider
export function StoreProvider(props: React.PropsWithChildren<unknown>) {
  const [state, dispatch] = React.useReducer<React.Reducer<AppState, Action>>(reducer, initialState)
  return <Store.Provider value={{ state, dispatch }} {...props} />
}
