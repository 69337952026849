import { Container, Stack, TextField, Typography, styled } from '@mui/material'
import { useCallback, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import { RoundedButton } from '../components/button'
import { CommonDialog } from '../components/dialog'
import { InputTitle } from '../components/form'
import { PageTitle, SectionTitle } from '../components/typography'
import { useSnackbar } from '../context/snackbarContext'
import { usePostContactMessage } from '../hooks/uploadHooks'
import { getError } from '../utils'

/**
 * お問い合わせページ
 *
 */
export default function ContactPage() {
  // hooks
  const navigate = useNavigate()
  const snackbar = useSnackbar()

  // states
  const [email, setEmail] = useState('')
  const [customerName, setCustomerName] = useState('')
  const [company, setCompany] = useState('')
  const [message, setMessage] = useState('')
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const { mutateAsync: postContact, isPending: loadingUpdate } = usePostContactMessage()

  const submitHandler = async () => {
    try {
      await postContact({
        email: email,
        customerName: customerName,
        company: company,
        message: message
      })
      setOpenConfirmDialog(false)
      navigate('/contact/complete')
    } catch (err) {
      snackbar.showSnackbar(getError(err), 'error')
    }
  }

  const handleConfirm = useCallback(async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setOpenConfirmDialog(true)
  }, [])

  return (
    <>
      <Helmet>
        <title>Visnu EC - お問い合わせ</title>
      </Helmet>
      <Container maxWidth="md" sx={{ position: 'relative', pt: '200px', pb: '130px' }}>
        <Stack gap={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <PageTitle title="Contact Us" subtitle="お問い合わせ" />
          <Typography fontSize={16} lineHeight={3} fontWeight={500} textAlign={'center'}>
            サービスをご利用いただきまして誠にありがとうございます。
            <br />
            お気づきの点・ご不明点などございましたら、お気軽に下記フォームよりお問い合わせください。
            <br />
            内容を確認し、担当からご連絡差し上げます。
          </Typography>

          <Stack component={'form'} onSubmit={handleConfirm} sx={{ width: '100%' }} gap={3}>
            <SectionTitle>お問い合わせフォーム</SectionTitle>
            <Stack px="30px" gap={3}>
              <Stack direction={'row'} alignItems={'center'} gap="50px">
                <InputTitle>氏名</InputTitle>
                <StyledTextField
                  size="small"
                  sx={{ flexGrow: 1 }}
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                  required
                />
              </Stack>
              <Stack direction={'row'} alignItems={'center'} gap="50px">
                <InputTitle>メールアドレス</InputTitle>
                <StyledTextField
                  size="small"
                  sx={{ flexGrow: 1 }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Stack>
              <Stack direction={'row'} alignItems={'center'} gap="50px">
                <InputTitle sx={{ pb: '26px' }}>会社名・組織名</InputTitle>
                <Stack flexGrow={1} gap={1}>
                  <StyledTextField size="small" value={company} onChange={(e) => setCompany(e.target.value)} />
                  <Typography fontSize={12} color="#B1B1B1" fontWeight={600}>
                    ※個人の方は空欄で問題ありません。
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={'row'} alignItems={'start'} gap="50px">
                <InputTitle>お問い合わせ内容</InputTitle>
                <StyledTextField
                  multiline
                  rows={8}
                  size="small"
                  sx={{ flexGrow: 1 }}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                />
              </Stack>
            </Stack>
            <Stack alignItems={'center'} pt={3}>
              <RoundedButton type="submit" color="orange">
                内容を確認
              </RoundedButton>
            </Stack>
          </Stack>
        </Stack>
      </Container>
      <ContactConfirmDialog
        open={openConfirmDialog}
        onClose={() => {
          setOpenConfirmDialog(false)
        }}
        onSubmit={() => submitHandler()}
        values={{ email, customerName, company, message }}
        loading={loadingUpdate}
      />
    </>
  )
}

const StyledTextField = styled(TextField)({
  background: '#FFFFFF'
})

const ContactConfirmDialog = ({
  open,
  onClose,
  onSubmit,
  values: { email, customerName, company, message },
  loading
}: {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  values: {
    email: string
    customerName: string
    company: string
    message: string
  }
  loading: boolean
}) => {
  return (
    <CommonDialog
      onClose={onClose}
      open={open}
      title="Confirmation of your inquiry"
      subtitle="お問い合わせ内容の確認"
      onSubmit={onSubmit}
      loading={loading}
    >
      <SectionTitle>お問い合わせ内容</SectionTitle>
      <Stack px="30px" gap={6}>
        <Stack direction={'row'} alignItems={'center'} gap="50px">
          <InputTitle>氏名</InputTitle>
          <Typography fontSize={16} fontWeight={600}>
            {customerName}
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap="50px">
          <InputTitle>メールアドレス</InputTitle>
          <Typography fontSize={16} fontWeight={600}>
            {email}
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'center'} gap="50px">
          <InputTitle>会社名・組織名</InputTitle>
          <Typography fontSize={16} fontWeight={600}>
            {company}
          </Typography>
        </Stack>
        <Stack direction={'row'} alignItems={'start'} gap="50px">
          <InputTitle>お問い合わせ内容</InputTitle>
          <Typography fontSize={16} fontWeight={600}>
            {message}
          </Typography>
        </Stack>
      </Stack>
    </CommonDialog>
  )
}
