import { useAuth0 } from '@auth0/auth0-react'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import HelpIcon from '@mui/icons-material/Help'
import LogoutIcon from '@mui/icons-material/Logout'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import {
  Avatar,
  Box,
  ButtonBase,
  Container,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Typography
} from '@mui/material'
import { motion } from 'framer-motion'
import { ReactNode, useCallback, useContext, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Store } from '../Store'
import { useUserInfo } from '../hooks/userInfoHooks'
import { RoundedButton } from './button'

export const Header = () => {
  return (
    <Box component={'header'} sx={{ position: 'fixed', zIndex: 3 }}>
      <Stack direction={'row'} p={'30px'}>
        <Box sx={{ flexGrow: 1 }}>
          <Link to="/">
            <Box
              component={'img'}
              src={'/visnu-logo-white.png'}
              alt={'logo'}
              sx={{ filter: 'invert(1)', width: 120 }}
            />
          </Link>
        </Box>
      </Stack>
    </Box>
  )
}

export const SideMenu = () => {
  return (
    <Box
      sx={{
        position: 'sticky',
        zIndex: 1,
        bottom: 70
      }}
    >
      <List sx={{ pl: '60px' }}>
        <ListItemButton component={Link} to="/mypage" disableGutters sx={{ display: 'flex', gap: '4px', width: 120 }}>
          <AccountBoxIcon sx={{ color: '#FF7F00' }} />
          <Typography fontSize={13} fontWeight={500}>
            My page
          </Typography>
        </ListItemButton>
        <ListItemButton component={Link} to="/cart" disableGutters sx={{ display: 'flex', gap: '4px', width: 120 }}>
          <ShoppingCartIcon sx={{ color: '#FF7F00' }} />
          <Typography fontSize={13} fontWeight={500}>
            Cart
          </Typography>
        </ListItemButton>
        <ListItemButton
          disableGutters
          sx={{
            // display: "flex",
            display: 'none',
            gap: '4px',
            width: 120
          }}
        >
          <HelpIcon sx={{ color: '#236CB5' }} />
          <Typography fontSize={13} fontWeight={500}>
            Support
          </Typography>
        </ListItemButton>
      </List>
    </Box>
  )
}

export const UserInfo = () => {
  const { dispatch } = useContext(Store)
  const { logout, isLoading, isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const userInfo = useUserInfo(getAccessTokenSilently, getAccessTokenWithPopup)

  const [openPopover, setOpenPopover] = useState(false)
  const popoverAnchorEl = useRef<HTMLDivElement>(null)

  const handleClosePopover = useCallback(() => {
    setOpenPopover(false)
  }, [])
  const handleLogOut = useCallback(() => {
    dispatch({ type: 'USER_SIGNOUT' })
    logout({
      logoutParams: {
        returnTo: window.location.origin
      }
    })
  }, [logout, dispatch])

  return (
    <Box
      sx={{
        position: 'sticky',
        zIndex: 3,
        bottom: 0,
        py: 2
      }}
    >
      <ButtonBase
        onClick={() => {
          setOpenPopover(true)
        }}
      >
        <Stack ref={popoverAnchorEl} direction={'row'} alignItems={'center'} height={'100%'} gap="10px" px="30px">
          <Avatar />
          <Box>
            {!isLoading && !isAuthenticated ? (
              <Typography fontSize={11} textAlign={'left'}>
                未ログイン
              </Typography>
            ) : userInfo.isLoading ? (
              <Typography fontSize={11} textAlign={'left'}>
                ロード中
              </Typography>
            ) : userInfo.data ? (
              <>
                <Typography fontSize={13} fontWeight={600} textAlign={'left'}>
                  {userInfo.data.name ?? userInfo.data.email}
                </Typography>
                <Typography fontSize={11} textAlign={'left'}>
                  としてログイン中
                </Typography>
              </>
            ) : (
              <Typography fontSize={11} textAlign={'left'}>
                未ログイン
              </Typography>
            )}
          </Box>
        </Stack>
      </ButtonBase>
      <Popover
        open={openPopover}
        onClose={handleClosePopover}
        anchorEl={popoverAnchorEl.current}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
      >
        <List sx={{ width: 300 }}>
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogOut}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="ログアウト" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </Box>
  )
}

export const FooterHeight = 420

export const Footer = () => {
  return (
    <Box
      component={'footer'}
      sx={{
        pt: '50px',
        pb: '100px',
        background: '#3C3C3C',
        position: 'relative',
        zIndex: 4,
        height: FooterHeight
      }}
    >
      <Container maxWidth="md">
        <Stack alignItems={'flex-start'} gap={4}>
          <Box component="img" src="/visnu-logo-white.png" alt="logo" sx={{ height: 26 }} />
          <Stack direction={'row'} gap={'100px'} sx={{ alignItems: 'center' }}>
            <Typography fontSize={20} color={'white'}>
              Visnu株式会社 | Visnu inc.
            </Typography>
            <RoundedButton
              component={Link}
              to="/contact"
              color="secondaryGradient"
              hoverColor="#FF7F00"
              size="small"
              width={150}
            >
              Contact us
            </RoundedButton>
          </Stack>
          <Stack direction={'row'} gap={2} sx={{ alignItems: 'center' }}>
            <Stack gap={2} width={320}>
              <Box>
                <Typography color="white" fontSize={14} sx={{ whiteSpace: 'pre', wordBreak: 'keep-all' }}>
                  本社所在地：020-0024
                  <wbr />
                  岩手県盛岡市菜園1-3-6 農林会館8F
                </Typography>
              </Box>
              <Box>
                <Typography
                  color="white"
                  fontSize={14}
                  fontWeight={300}
                  sx={{ whiteSpace: 'pre', wordBreak: 'keep-all' }}
                >
                  8F, Nourin Kaikan, 1-3-6 Saien, Morioka,
                  <wbr />
                  Iwate 020-0024,Japan
                </Typography>
              </Box>
            </Stack>
            <Stack gap={2} width={320}>
              <Box>
                <Typography color="white" fontSize={14} sx={{ whiteSpace: 'pre', wordBreak: 'keep-all' }}>
                  東京オペレーションセンター：101-0052
                  <wbr />
                  東京都千代田区神田小川町3-1-5 須田ビル8F
                </Typography>
              </Box>
              <Box>
                <Typography
                  color="white"
                  fontSize={14}
                  fontWeight={300}
                  sx={{ whiteSpace: 'pre', wordBreak: 'keep-all' }}
                >
                  Suda Bldg. 8F, 3-1-5 Kanda Ogawamachi,
                  <wbr />
                  Chiyoda-ku, Tokyo 101-0052, Japan
                </Typography>
              </Box>
            </Stack>
          </Stack>
          <Stack direction={'row'} gap={2} sx={{ alignItems: 'center' }}>
            <Link style={{ color: 'white' }} to="/commerce-disclosure">
              特定商取引法に基づく表記
            </Link>
          </Stack>
          <Typography color="white" fontSize={12} fontWeight={300} sx={{ alignSelf: 'center' }}>
            Copyright © Visnu Inc. All Right reserved.
          </Typography>
        </Stack>
      </Container>
    </Box>
  )
}

export const ProductCategoryChip = (props: { children?: ReactNode }) => {
  return (
    <Box
      sx={{
        borderRadius: '3px',
        background: '#868686',
        width: 70,
        height: 20,
        minHeight: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography
        sx={{
          color: 'white',
          fontSize: 11,
          lineHeight: 1
        }}
      >
        {props.children}
      </Typography>
    </Box>
  )
}

export const MotionLink = motion(Link)
