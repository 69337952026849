import { Box, Stack } from '@mui/material'

export const DottedDivider = () => {
  return (
    <Stack direction="row" width={150} justifyContent={'space-between'}>
      {Array.from({ length: 9 }).map((_, i) => (
        <Box
          key={i}
          sx={{
            width: 5,
            height: 5,
            backgroundColor: '#3C3C3C',
            borderRadius: 5
          }}
        />
      ))}
    </Stack>
  )
}
