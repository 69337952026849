import Chart from 'react-google-charts'
import { Helmet } from 'react-helmet-async'
import LoadingBox from '../../components/LoadingBox'
import MessageBox from '../../components/MessageBox'
import { useGetOrderSummaryQuery } from '../../hooks/orderHooks'
import { getError } from '../../utils'
import { Box, Container, Grid, Stack, Typography } from '@mui/material'

function CategoryName(logicName: string) {
  return logicName
}

export default function DashboardPage() {
  const { data: summary, isLoading, error } = useGetOrderSummaryQuery()

  return (
    <div>
      <Helmet>
        <title>Visnu EC - 管理者ページ</title>
      </Helmet>
      <Container maxWidth="md" sx={{ py: 5 }}>
        <h1>管理者ページ</h1>
        <br />
        <br />
        {isLoading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{getError(error)}</MessageBox>
        ) : (
          <Stack gap={2}>
            <Grid container spacing={'20px'} rowSpacing={'60px'}>
              <Grid xs={6} sm={4} md={3}>
                <Box
                  sx={{
                    display: 'block',
                    borderRadius: '16px',
                    background: '#F8F8F8',
                    overflow: 'hidden',
                    textDecoration: 'none',
                    color: '#3C3C3C',
                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
                  }}
                >
                  <Stack p={'10px'} justifyContent={'space-between'}>
                    <Stack gap={0.5}>
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: 500,
                          width: '100%',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: 'vertical',
                          display: '-webkit-box'
                        }}
                      >
                        ユーザ
                      </Typography>
                    </Stack>
                    <Typography>{summary && summary.users[0] ? summary.users[0].numUsers : 0} 名</Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={6} sm={4} md={1}></Grid>
              <Grid xs={6} sm={4} md={3}>
                <Box
                  sx={{
                    display: 'block',
                    borderRadius: '16px',
                    background: '#F8F8F8',
                    overflow: 'hidden',
                    textDecoration: 'none',
                    color: '#3C3C3C',
                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
                  }}
                >
                  <Stack p={'10px'} justifyContent={'space-between'}>
                    <Stack gap={0.5}>
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: 500,
                          width: '100%',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: 'vertical',
                          display: '-webkit-box'
                        }}
                      >
                        注文
                      </Typography>
                    </Stack>
                    <Typography>{summary && summary.orders[0] ? summary.orders[0].numOrders : 0} 件</Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid xs={6} sm={4} md={1}></Grid>
              <Grid xs={6} sm={4} md={3}>
                <Box
                  sx={{
                    display: 'block',
                    borderRadius: '16px',
                    background: '#F8F8F8',
                    overflow: 'hidden',
                    textDecoration: 'none',
                    color: '#3C3C3C',
                    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
                  }}
                >
                  <Stack p={'10px'} justifyContent={'space-between'}>
                    <Stack gap={0.5}>
                      <Typography
                        sx={{
                          fontSize: 16,
                          fontWeight: 500,
                          width: '100%',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          WebkitLineClamp: 3,
                          WebkitBoxOrient: 'vertical',
                          display: '-webkit-box'
                        }}
                      >
                        売上
                      </Typography>
                    </Stack>
                    <Typography>{summary && summary.orders[0] ? '¥' + summary.orders[0].totalSales : 0}</Typography>
                  </Stack>
                </Box>
              </Grid>
            </Grid>
            <div>
              <h2>売上</h2>
              <Stack sx={{ boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)' }}>
                {summary && summary.dailyOrders.length > 0 ? (
                  <Chart
                    width="100%"
                    height="400px"
                    chartType="AreaChart"
                    loader={<div>Loading Chart...</div>}
                    data={[['Date', 'Sales'], ...summary.dailyOrders.map((x) => [x._id, x.sales])]}
                  ></Chart>
                ) : (
                  <MessageBox>売上なし</MessageBox>
                )}
              </Stack>
            </div>
            <div>
              <h2>カテゴリ</h2>
              <Stack sx={{ boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)' }}>
                {summary && summary.productCategories.length > 0 ? (
                  <Chart
                    width="100%"
                    height="400px"
                    chartType="PieChart"
                    loader={<div>Loading Chart...</div>}
                    data={[
                      ['Category', 'Products'],
                      ...summary.productCategories.map((x) => [CategoryName(x._id), x.count])
                    ]}
                  ></Chart>
                ) : (
                  <MessageBox>カテゴリなし</MessageBox>
                )}
              </Stack>
            </div>
          </Stack>
        )}
      </Container>
    </div>
  )
}
