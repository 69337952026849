import { useAuth0 } from '@auth0/auth0-react'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Pagination,
  PaginationItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useSearchParams } from 'react-router-dom'
import { Store } from '../Store'
import { CategoryButton, RoundedButton } from '../components/button'
import { MotionLink, ProductCategoryChip } from '../components/common'
import { DottedDivider } from '../components/divider'
import { HeroMessage } from '../components/typography'
import { useSnackbar } from '../context/snackbarContext'
import { useAddCartMutation } from '../hooks/cartHooks'
import { useGetCategoriesQuery, useGetProductsbyMultipleQueries } from '../hooks/productHooks'
import { CartItem } from '../types/Cart'
import { convertProductToCartItem, formatPrice, getError, getImageUrl } from '../utils'
import LoadingBox from '../components/LoadingBox'
import MessageBox from '../components/MessageBox'

// TODO: カテゴリーの定数をどこかにまとめる
const CATEGORYALL = 'all'

type Filter = {
  category?: string
  price?: string
  order?: string
  page?: number
  query?: string
}

type Products = NonNullable<ReturnType<typeof useGetProductsbyMultipleQueries>['data']>

export default function HomePage() {
  const [searchParams] = useSearchParams()

  // memos
  const queryParamsFilters = useMemo<Filter>(() => {
    const page = (() => {
      try {
        const p = searchParams.get('page')
        return p ? Number(p) : 1
      } catch (e) {
        return 1
      }
    })()

    return {
      category: searchParams.get('category') || 'all',
      query: searchParams.get('query') || '',
      price: searchParams.get('price') || 'all',
      order: searchParams.get('order') || 'newest',
      page
    }
  }, [searchParams])

  const products = useGetProductsbyMultipleQueries({
    query: queryParamsFilters.query,
    category: queryParamsFilters.category,
    price: queryParamsFilters.price,
    page: queryParamsFilters.page,
    order: queryParamsFilters.order
  })

  return (
    <>
      <Helmet>
        <title>Visnu EC</title>
      </Helmet>
      <Box component="main">
        <Box sx={{ background: '#FFFCF8', position: 'relative', zIndex: 2 }}>
          <Container maxWidth="md">
            <Hero />
          </Container>
        </Box>
        <Stack>
          <ProductListHeader />
          <ProductList
            isLoading={products.isLoading}
            products={products.data}
            currentPage={Number(queryParamsFilters.page)}
          />
        </Stack>
      </Box>
      <SignUpFloatingFooter />
    </>
  )
}

const Hero = () => {
  return (
    <Stack height={'100svh'} justifyContent={'space-evenly'} py={'96px'} gap={8}>
      <Stack gap={4}>
        <Typography fontSize={32} fontWeight={600}>
          Do you have adequate emergency supplies?
        </Typography>
        <Typography fontSize={32} fontWeight={600}>
          十分な防災グッズは備えていますか？
        </Typography>
        <Divider sx={{ borderBottomWidth: 2, borderColor: '#707070' }} />
        <Typography>
          In preparation for disasters, it is not only individuals but companies are required to have a stockpile of
          supplies. In the event of an emergency, be well prepared to ensure that your business will not stop operations
          and that you will be able to survive.
        </Typography>
      </Stack>
      <HeroMessage />
      <ScrollIndicator />
    </Stack>
  )
}

const ScrollIndicator = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        color: '#FFFFFF',
        zIndex: 2,
        textAlign: 'center',
        left: '50%',
        bottom: '10vh',
        opacity: 1,
        transform: 'translate(-50%, -50%)',
        fontSize: '1.6rem',
        ':before': {
          content: '""',
          position: 'absolute',
          left: '50%',
          bottom: '-50px',
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          background: '#868686',
          transform: 'translate(-50%, 0%)',
          animation: 'circleMove 1.6s ease-in-out infinite, circleMoveHide 1.6s ease-out infinite'
        },
        ':after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          width: '1px',
          height: '12vh',
          mt: '15px',
          left: '50%',
          backgroundColor: '#868686',
          textShadow: '0 0 10px #868686',
          transform: 'translate(-50%, 0%)'
        },
        '@keyframes circleMove': {
          '0%': {
            bottom: '-30px'
          },
          '100%': {
            bottom: '-120px'
          }
        },
        '@keyframes circleMoveHide': {
          '0%': {
            opacity: 0
          },
          '50%': {
            opacity: 1
          },
          '80%': {
            opacity: 0.9
          },
          '100%': {
            opacity: 0
          }
        }
      }}
    >
      <Typography fontSize={20} fontWeight={400}>
        Scroll to know Visnu EC
      </Typography>
    </Box>
  )
}

const Category = () => {
  const { data: categoryList, isLoading, error } = useGetCategoriesQuery()
  const [searchParams, setSearchParams] = useSearchParams()

  const category = useMemo(() => {
    return searchParams.get('category') || CATEGORYALL
  }, [searchParams])

  const handleChangeCategory = useCallback(
    (category: string) => {
      // カテゴリ変更時はページを1に戻す
      searchParams.delete('page')
      searchParams.set('category', category)
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )

  // ローディング中
  if (isLoading) {
    return <LoadingBox />
  }

  //エラー表示
  if (error) {
    return <MessageBox variant="danger">{getError(error)}</MessageBox>
  }

  if (!categoryList) {
    // 表示は考える
    return <MessageBox variant="danger">カテゴリが表示できません</MessageBox>
  }

  // この時点でcategoryListは絶対存在する
  return (
    <Grid container spacing={1}>
      <Grid item>
        <CategoryButton
          title="すべて"
          subtitle="All"
          active={category === CATEGORYALL}
          onClick={() => handleChangeCategory(CATEGORYALL)}
        />
      </Grid>
      {categoryList.categories.map((Category) => (
        <Grid item>
          <CategoryButton
            key={Category.label}
            title={Category.jpLabel}
            subtitle={Category.label}
            active={Category.label === searchParams.get('category')}
            onClick={() => handleChangeCategory(Category.label)}
          />
        </Grid>
      ))}
    </Grid>
  )
}

const ProductListHeader = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [searchName, setSearchName] = useState<string>('')

  const sort = useMemo(() => {
    return searchParams.get('order') || 'newest'
  }, [searchParams])
  const query = useMemo(() => {
    return searchParams.get('query') || ''
  }, [searchParams])

  const handleChangeSort = useCallback(
    (e: SelectChangeEvent) => {
      searchParams.set('order', e.target.value)
      setSearchParams(searchParams)
    },
    [searchParams, setSearchParams]
  )
  const handleClearSearch = useCallback(() => {
    setSearchName('')
    searchParams.delete('query')
    setSearchParams(searchParams)
  }, [searchParams, setSearchParams])
  const handleSearchByName = useCallback(() => {
    if (!searchName) {
      searchParams.delete('query')
    } else {
      searchParams.set('query', searchName)
    }
    setSearchParams(searchParams)
  }, [searchName, searchParams, setSearchParams])
  const handleChangeSearchName = useCallback(
    (name: string) => {
      setSearchName(name)
      if (!name) return handleClearSearch()
    },
    [handleClearSearch]
  )

  // effects
  useEffect(() => {
    setSearchName(query)
  }, [query, setSearchName])

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: 2,
        background: '#FFFCF8'
      }}
    >
      <Container maxWidth="md">
        <Stack direction={'row'} gap={3} pt={3}>
          <Stack gap={1} sx={{ maxWidth: '67%' }}>
            <Typography fontSize={24} fontWeight={'bold'}>
              ITEM CATEGORY
            </Typography>
            <Category />
          </Stack>
          <Stack gap={2} sx={{ flexGrow: 1 }}>
            <Stack gap={1}>
              <Typography fontSize={24} fontWeight={'bold'}>
                SEARCH
              </Typography>
              <Box sx={{ pl: '20px' }}>
                <TextField
                  size="small"
                  sx={{
                    width: '100%',
                    background: '#F2F2F2',
                    borderRadius: '30px',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 'none'
                    },
                    '.MuiInputBase-input': {
                      py: 0.5
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: '#FF7F00' }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <IconButton
                        size="small"
                        onClick={() => {
                          handleClearSearch()
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </IconButton>
                    )
                  }}
                  value={searchName}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSearchByName()
                    }
                  }}
                  onChange={(e) => {
                    handleChangeSearchName(e.target.value)
                  }}
                />
              </Box>
            </Stack>
            <Stack direction={'row'} alignItems={'center'} gap={1}>
              <Typography fontSize={16} fontWeight={500}>
                SORT BY
              </Typography>
              <Select
                variant="outlined"
                sx={{
                  flexGrow: 1,
                  '.MuiSelect-icon': {
                    color: '#FF7F00'
                  },
                  '.MuiSelect-outlined': {
                    py: 0.5
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 'none'
                  },
                  background: '#F2F2F2'
                }}
                size="small"
                value={sort}
                onChange={handleChangeSort}
              >
                <MenuItem value="newest">新規商品</MenuItem>
                <MenuItem value="lowest">値段: 安い順</MenuItem>
                <MenuItem value="highest">値段:高い順</MenuItem>
              </Select>
            </Stack>
          </Stack>
        </Stack>
        <Divider sx={{ borderBottomWidth: 2, borderColor: '#707070', my: 2 }} />
      </Container>
    </Box>
  )
}

const ProductList = (props: { products?: Products; isLoading: boolean; currentPage: number }) => {
  const [searchParams, setSearchParams] = useSearchParams()
  return (
    <Container maxWidth="md">
      <Grid container spacing={'20px'} rowSpacing={'60px'}>
        {props.products?.products.map((product) => (
          <Grid item key={product.slug} xs={6} sm={4} md={3}>
            <ProductCard product={product} />
          </Grid>
        ))}
        {Array.from({
          length: 12 - (props.products?.products.length ?? 0)
        }).map((_, i) => (
          <Grid item key={`skeleton-${i}`} xs={6} sm={4} md={3}>
            {props.isLoading ? <Skeleton variant="rectangular" animation="wave" height={405} /> : <Box height={405} />}
          </Grid>
        ))}
      </Grid>
      <Stack alignItems={'center'} py="60px" gap={4.5}>
        <DottedDivider />
        <Stack direction={'row'} gap={2} justifyContent={'center'}>
          <Pagination
            page={props.currentPage}
            count={props.products?.pages}
            onClick={(e) => {
              const input = e.target as HTMLElement
              searchParams.set('page', input.innerText.toString())
              setSearchParams(searchParams)
            }}
            renderItem={(item) => <PaginationItem {...item} />}
          />
        </Stack>
      </Stack>
    </Container>
  )
}

const ProductCard = (props: { product: Products['products'][number] }) => {
  const { state, dispatch } = useContext(Store)
  const { userInfo } = state

  const { mutateAsync: addCart, isPending } = useAddCartMutation()
  const snackbar = useSnackbar()

  //商品をカートに追加
  const handleAddToCart = async (item: CartItem) => {
    let quantity = 1
    if (!userInfo) {
      //カートにもう商品が存在するかを確認
      const existItem = state.cart.cartItems.find((x) => x.id === props.product._id)
      //存在した場合は数量を1個足す
      quantity = existItem ? existItem.quantity + 1 : 1

      dispatch({
        type: 'CART_ADD_ITEM',
        payload: { ...item, quantity }
      })
      snackbar.showSnackbar(`カートに追加しました : ${props.product.name}`, 'success')
      return
    }
    try {
      await addCart({
        cartItem: {
          id: item.id,
          quantity: quantity
        }
      })
      snackbar.showSnackbar(`カートに追加しました : ${props.product.name}`, 'success')
    } catch (err) {
      snackbar.showSnackbar(getError(err), 'error')
    }
  }

  return (
    <Box
      component={MotionLink}
      to={`/product/${props.product.slug}`}
      whileHover={{
        boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)'
      }}
      sx={{
        display: 'block',
        borderRadius: '16px',
        background: '#F8F8F8',
        overflow: 'hidden',
        cursor: 'pointer',
        textDecoration: 'none',
        color: '#3C3C3C'
      }}
    >
      <Box
        component={'img'}
        src={getImageUrl(props.product.images[0])}
        alt={props.product.name}
        loading="lazy"
        sx={{
          width: '100%',
          height: 225,
          objectFit: 'cover',
          background: 'white'
        }}
      />
      <Stack p={'10px'} sx={{ height: 175 }} justifyContent={'space-between'}>
        <Stack gap={0.5}>
          <ProductCategoryChip>{props.product.category}</ProductCategoryChip>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 500,
              width: '100%',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              display: '-webkit-box'
            }}
          >
            {props.product.name}
          </Typography>
        </Stack>
        <Stack gap={0.5}>
          <Typography sx={{ alignSelf: 'flex-end' }}>{formatPrice(props.product.prices.oneTime.unitAmount)}</Typography>
          <RoundedButton
            color="blue"
            size="tiny"
            width={100}
            disabled={isPending}
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.preventDefault()
              e.stopPropagation()
              handleAddToCart(convertProductToCartItem(props.product))
            }}
            sx={{ alignSelf: 'center', minHeight: 20 }}
          >
            カートに追加
          </RoundedButton>
        </Stack>
      </Stack>
    </Box>
  )
}

const SignUpFloatingFooter = () => {
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0()

  const handleLogIn = useCallback(() => {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin
      }
    })
  }, [loginWithRedirect])

  const handleSignUp = useCallback(() => {
    loginWithRedirect({
      authorizationParams: {
        redirect_uri: window.location.origin,
        screen_hint: 'signup'
      }
    })
  }, [loginWithRedirect])

  if (isLoading || isAuthenticated) {
    return null
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 5,
        bottom: 0,
        width: '100%',
        height: 96
      }}
    >
      <Container sx={{ height: '100%' }}>
        <Stack
          height={'100%'}
          direction={'row'}
          px={'30px'}
          justifyContent={'center'}
          alignItems={'center'}
          gap="100px"
          sx={{
            backdropFilter: 'blur(10px)',
            paddingRight: '200px'
          }}
        >
          <RoundedButton onClick={handleLogIn}>ログイン</RoundedButton>
          <RoundedButton onClick={handleSignUp} color="black">
            会員登録はこちら
          </RoundedButton>
        </Stack>
      </Container>
    </Box>
  )
}
