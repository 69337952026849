import { ApiError } from './types/ApiError'
import { CartItem } from './types/Cart'
import type { apiClients } from './lib/apiClient'

export function hasMessage(obj: unknown): obj is ApiError {
  return (obj as ApiError)?.message !== undefined && typeof (obj as ApiError).message === 'string'
}

export function hasResponseDataMessage(obj: unknown): obj is ApiError {
  return (
    (obj as ApiError)?.response !== undefined &&
    typeof obj === 'object' &&
    obj !== null &&
    'response' in obj &&
    typeof obj.response === 'object' &&
    obj.response !== null &&
    'data' in obj.response &&
    typeof obj.response.data === 'object' &&
    obj.response.data !== null &&
    'message' in obj.response.data
  )
}

/**
 * APIエラーのエラーを表示
 *
 */
export const getError = (apiError: unknown) => {
  if (hasMessage(apiError)) {
    return apiError.message
  } else if (hasResponseDataMessage(apiError)) {
    return apiError.response.data.message
  } else {
    return 'unknown error'
  }
}

/**
 * 商品をカートオブジェクトに入れ込む
 *
 */
type Product = Awaited<ReturnType<(typeof apiClients)['/api/products/slug/:slug']['GET']['client']>>['body']

export const convertProductToCartItem = (product: Product): CartItem => {
  const cartItem: CartItem = {
    id: product._id,
    quantity: 1
  }
  return cartItem
}

export const formatPrice = (price: number) => {
  return (
    new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY'
    }).format(price) + '-'
  )
}

export const formatDate = (date: Date) => {
  return new Intl.DateTimeFormat('ja-JP').format(date)
}

export const getImageUrl = (image?: string | null) => {
  return image ? image : undefined
}
