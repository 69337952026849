import { useMutation, useQuery } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { getAPIAccessToken } from '../lib/auth0'
import { apiClients } from '../lib/apiClient'

/**
 * get all users
 * 例: /api/users/all
 */
export const useGetAllUsers = (page: number) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: ['all-users', page],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/users/all'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        query: { page }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 * ユーザー情報を取得するＡＰＩをbackendにリクエスト
 * 例: api/users/signin
 */
export const useSigninMutation = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation({
    mutationFn: async (body: Parameters<(typeof apiClients)['/api/users/profile']['POST']['client']>[0]['body']) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/users/profile'].POST.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: body
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 *
 * 例: api/users/id/65711ec5d4094433e6ae830e
 */
export const useUsersByIdQuery = (id: string) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useQuery({
    queryKey: ['user', id],
    queryFn: async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/users/:id'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { id }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 * 個別のユーザを更新するＡＰＩをbackendにリクエスト
 * 例: api/users/id/65711ec5d4094433e6ae830e
 */
export const usePostUsersByIdQuery = (id: string) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation({
    mutationFn: async (body: Parameters<(typeof apiClients)['/api/admin/users/:id']['PUT']['client']>[0]['body']) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/users/:id'].PUT.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { id },
        body
      })

      if (!res.ok) {
        return Promise.reject(res.body.message)
      }

      return res.body
    }
  })
}
