import { useMutation } from '@tanstack/react-query'
import { useAuth0 } from '@auth0/auth0-react'
import { getAPIAccessToken } from '../lib/auth0'
import { apiClients } from '../lib/apiClient'

/**
 *
 * 例: api/upload/image
 */
export const usePostUploadImageQuery = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation({
    mutationFn: async (formData: Parameters<(typeof apiClients)['/api/admin/image']['POST']['client']>[0]['form']) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/image'].POST.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        form: formData
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 *
 * 例: api/upload/contact
 */
export const usePostContactMessage = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation({
    mutationFn: async (user: Parameters<(typeof apiClients)['/api/upload/contact']['POST']['client']>[0]['body']) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/upload/contact'].POST.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: user
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}

/**
 *
 * 例: api/upload/delete
 */
export const usePostDeleteImageQuery = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  return useMutation({
    mutationFn: async (formData: { productId: string; fileName: string }) => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/image/:productId'].DELETE.client({
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { productId: formData.productId },
        body: { fileName: formData.fileName }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
  })
}
