import { Outlet } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useContext } from 'react'
import { Store } from '../Store'

//ログイン権限
export default function ProtectedRoute() {
  const { loginWithRedirect } = useAuth0()
  const {
    state: { userInfo }
  } = useContext(Store)
  const isauth = useAuth0().isAuthenticated

  if (userInfo || isauth) {
    return <Outlet />
  } else {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname
      },
      authorizationParams: {
        screen_hint: 'signup'
      }
    })
  }
}
