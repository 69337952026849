import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown'
import { Stack, Typography } from '@mui/material'

export const PageTitle = (props: { title: string; subtitle: string }) => {
  return (
    <Stack gap={2} alignItems={'center'}>
      <Typography fontSize={24} fontWeight={600} color={'#868686'}>
        {props.subtitle}
      </Typography>
      <Typography fontSize={32} fontWeight={700}>
        {props.title}
      </Typography>
    </Stack>
  )
}

export const SectionTitle = (props: { children: React.ReactNode }) => {
  return (
    <Stack
      direction={'row'}
      gap={'10px'}
      sx={{
        background: '#FDF1E0',
        borderRadius: '5px',
        alignItems: 'center',
        px: '10px',
        py: '8px'
      }}
    >
      <ExpandCircleDownIcon
        sx={{
          transform: 'rotate(270deg);',
          fontSize: '10px'
        }}
      />
      <Typography fontSize={16} fontWeight={600}>
        {props.children}
      </Typography>
    </Stack>
  )
}
export const HeroMessage = () => {
  return (
    <Stack alignItems={'center'} gap="75px">
      <Stack alignItems={'center'}>
        <Typography>Absolutely necessary!</Typography>
        <Typography fontSize={32} fontWeight={300}>
          <Typography component={'span'} fontSize={32} fontWeight={600}>
            Emergency
          </Typography>{' '}
          supplies
        </Typography>
      </Stack>
      <Typography fontSize={26} fontWeight={600} lineHeight={'64px'} textAlign={'center'}>
        すべての人が、
        <br />
        いつも通りに暮らせる世界を。
      </Typography>
    </Stack>
  )
}
