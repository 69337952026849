import { Container, Stack, Typography } from '@mui/material'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { RoundedButton } from '../components/button'
import { PageTitle } from '../components/typography'

/**
 * お問い合わせ完了ページ
 *
 */
export default function ContactCompletePage() {
  return (
    <>
      <Helmet>
        <title>Visnu EC - お問い合わせ完了</title>
      </Helmet>
      <Container maxWidth="md" sx={{ position: 'relative', pt: '200px', pb: '130px' }}>
        <Stack gap={3} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <PageTitle title="Inquiry Completed" subtitle="お問い合わせ完了" />
          <Typography fontSize={16} lineHeight={3} fontWeight={500} textAlign={'center'}>
            お問い合わせ誠にありがとうございます。
            <br />
            内容を確認し、早急に担当からご連絡差し上げます。
          </Typography>
          <Stack direction={'row'} gap={2} py={6}>
            <RoundedButton component={Link} to="/" color="gray">
              TOPページへ戻る
            </RoundedButton>
          </Stack>
        </Stack>
      </Container>
    </>
  )
}
