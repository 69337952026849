import { ButtonBase, Dialog, Stack } from '@mui/material'
import { motion } from 'framer-motion'
import { RoundedButton } from './button'
import { PageTitle } from './typography'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'

export const CommonDialog = ({
  open,
  loading,
  title,
  subtitle,
  closeButtonLabel,
  submitButtonLabel,
  onClose,
  onSubmit,
  children
}: {
  open: boolean
  loading: boolean
  title: string
  subtitle: string
  closeButtonLabel?: string
  submitButtonLabel?: string
  onClose: () => void
  onSubmit: () => void
  children: React.ReactNode
}) => {
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      onClose={onClose}
      open={open}
      sx={{ '.MuiDialog-paper': { background: '#FFFCF8' } }}
    >
      <Stack gap={6} sx={{ px: '100px', pt: '30px' }}>
        <PageTitle title={title} subtitle={subtitle} />
        <ButtonBase
          component={motion.button}
          disableRipple
          sx={{
            position: 'absolute',
            right: 20,
            top: 20,
            p: 0
          }}
          onClick={onClose}
          initial="rest"
          whileHover="hover"
          animate="rest"
        >
          <CancelRoundedIcon
            component={motion.svg}
            sx={{
              fontSize: '30px'
            }}
            variants={{
              rest: {
                color: '#868686'
              },
              hover: {
                color: '#3C3C3C'
              }
            }}
          />
        </ButtonBase>
        <>{children}</>
      </Stack>
      <Stack direction={'row'} gap={3} justifyContent={'center'} pt="83px" pb="50px">
        <RoundedButton color="gray" onClick={onClose}>
          {closeButtonLabel ?? '戻る'}
        </RoundedButton>
        <RoundedButton onClick={onSubmit} color="orange" loading={loading}>
          {submitButtonLabel ?? '保存'}
        </RoundedButton>
      </Stack>
    </Dialog>
  )
}
