import { createClients } from 'client-shared/api/ecClient'
import { HOST } from '../constants'

export const apiClients = createClients(HOST.API)

export async function getMineCart(token: string | undefined) {
  if (!token) return Promise.reject()

  const res = await apiClients['/api/cart'].GET.client({
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  if (!res.ok) {
    return Promise.reject()
  }

  return res.body
}
