import { useAuth0 } from '@auth0/auth0-react'
import CloseIcon from '@mui/icons-material/Close'
import { Alert, Box, Button, CssBaseline, IconButton, Snackbar, Stack } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, Outlet, ScrollRestoration } from 'react-router-dom'
import { Store } from './Store'
import { Footer, FooterHeight, Header, SideMenu, UserInfo } from './components/common'
import { getAPIAccessToken } from './lib/auth0'
import { getMineCart } from './lib/apiClient'
import { useAddBatchCartMutation } from './hooks/cartHooks'
import { useSigninMutation } from './hooks/userHooks'
import { useUserInfo, userInfoQueryKey } from './hooks/userInfoHooks'
import { ApiError } from './types/ApiError'
import { getError } from './utils'

function App() {
  const { dispatch, state } = useContext(Store)

  const { user, isAuthenticated, isLoading, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const { mutateAsync: signin } = useSigninMutation()
  const { mutateAsync: addCart } = useAddBatchCartMutation()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (isLoading) return
    if (!isAuthenticated || !user) {
      queryClient.resetQueries({ queryKey: [userInfoQueryKey] })
      return
    }
    const response = async () => {
      const email = user.email
      const auth0Sub = user.sub
      try {
        const data = await signin({
          email,
          auth0Sub
        })

        dispatch({ type: 'USER_SIGNIN', payload: data })
        const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
        const cartData = await getMineCart(token)

        if (cartData && cartData.cart && cartData.cart.cartItems.length > 0) {
          localStorage.removeItem('cartItems')
        } else {
          if (state.cart.cartItems) {
            addCart({ cartItems: state.cart.cartItems })
          }
        }
        localStorage.removeItem('cartItems')
      } catch (err) {
        alert(getError(err as ApiError))
      }
    }

    response()
  }, [
    addCart,
    dispatch,
    getAccessTokenSilently,
    getAccessTokenWithPopup,
    isAuthenticated,
    isLoading,
    queryClient,
    signin,
    state.cart.cartItems,
    user
  ])

  return (
    <Box sx={{ background: '#FFFCF8' }}>
      <Helmet>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <CssBaseline />
      <Header />
      <Box
        sx={{
          display: 'flex',
          minHeight: `calc(100vh - ${FooterHeight}px)`
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <SideMenu />
          <UserInfo />
        </Box>
        <Box sx={{ flex: 1 }}>
          <Outlet />
        </Box>
      </Box>
      <Footer />
      <ScrollRestoration
        getKey={(location) => {
          return location.pathname
        }}
      />
      <RequireUserInfoSnackbar />
    </Box>
  )
}

const RequireUserInfoSnackbar = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  // hooks
  const userInfo = useUserInfo(getAccessTokenSilently, getAccessTokenWithPopup)

  // states
  const [showRequireUserInfoSnackbar, setShowRequireUserInfoSnackbar] = useState<boolean>(false)

  // effects
  // UserInfoの状態によって表示を切り替える
  useEffect(() => {
    if (!userInfo.data) {
      setShowRequireUserInfoSnackbar(false)
      return
    }
    if (!userInfo.data.name || !userInfo.data.nameKana || !userInfo.data.phone) {
      setShowRequireUserInfoSnackbar(true)
      return
    }
    setShowRequireUserInfoSnackbar(false)
  }, [userInfo.data])

  return (
    <Snackbar
      open={showRequireUserInfoSnackbar}
      autoHideDuration={0}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={(theme) => ({
        width: theme.breakpoints.values.md
      })}
    >
      <Alert
        onClose={() => {
          setShowRequireUserInfoSnackbar(false)
        }}
        severity="info"
        variant="filled"
        sx={{ width: '100%' }}
        action={
          <Stack direction="row" spacing={2}>
            <Button component={Link} to={'/mypage'} color="inherit" size="small">
              マイページに移動
            </Button>
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowRequireUserInfoSnackbar(false)
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        }
      >
        お客様情報をご登録ください。
      </Alert>
    </Snackbar>
  )
}

export default App
