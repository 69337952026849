import { Alert, AlertColor, Snackbar } from '@mui/material'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'

export type SnackbarContextType = {
  message: string
  severity: AlertColor
  showSnackbar: (message: string, severity: AlertColor) => void
}

export type SnackbarProviderProps = React.PropsWithChildren

export const SnackbarContext = createContext<SnackbarContextType>({
  message: '',
  severity: 'error',
  showSnackbar: () => {}
})

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const context: SnackbarContextType = useContext(SnackbarContext)
  const [message, setMessage] = useState(context.message)
  const [severity, setSeverity] = useState(context.severity)

  const newContext: SnackbarContextType = useMemo(
    () => ({
      message,
      severity,
      showSnackbar: (message: string, severity: AlertColor) => {
        setMessage(message)
        setSeverity(severity)
      }
    }),
    [message, severity, setMessage, setSeverity]
  )

  const handleClose = useCallback(() => {
    setMessage('')
  }, [setMessage])

  return (
    <SnackbarContext.Provider value={newContext}>
      {children}
      <Snackbar
        open={newContext.message !== ''}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={5000}
        sx={{
          minWidth: '300px',
          width: '50%'
        }}
      >
        <Alert variant="filled" onClose={handleClose} severity={newContext.severity} sx={{ width: '100%' }}>
          {newContext.message}
        </Alert>
      </Snackbar>
    </SnackbarContext.Provider>
  )
}

export const useSnackbar = () => {
  return useContext(SnackbarContext)
}
