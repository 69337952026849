import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { getAPIAccessToken } from '../lib/auth0'
import { apiClients } from '../lib/apiClient'

export default function AdminRoute() {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [loading, setLoading] = useState(true)
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    const getUserInfo = async () => {
      const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)

      const res = await apiClients['/api/admin/role'].GET.client({
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      if (!res.ok) {
        return Promise.reject()
      }

      return res.body
    }
    getUserInfo()
      .then((data) => {
        setIsAdmin(data.isAdmin)
      })
      .catch(() => {
        setIsAdmin(false)
      })
      .finally(() => {
        setLoading(false)
      })
  })

  if (loading) {
    return <div>Loading...</div>
  }

  if (!isAdmin) {
    throw new Error()
  }

  return <Outlet />
}
