import {
  Box,
  Button,
  Container,
  Pagination,
  PaginationItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import LoadingBox from '../../components/LoadingBox'
import MessageBox from '../../components/MessageBox'
import { useGetProductsListQuery, usePostCreateProductsQuery } from '../../hooks/productHooks'
import { apiClients } from '../../lib/apiClient'
import { getError, getImageUrl } from '../../utils'
import { getAPIAccessToken } from '../../lib/auth0'

export default function ProductsListPage() {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()

  const navigate = useNavigate()
  const { search } = useLocation()
  const sp = new URLSearchParams(search)
  const page = (() => {
    try {
      const p = sp.get('page')
      return p ? Number(p) : 1
    } catch (e) {
      return 1
    }
  })()
  const { data: responseProducts, isLoading, error } = useGetProductsListQuery(page)

  const { mutateAsync: createProduct } = usePostCreateProductsQuery()

  const createHandler = async () => {
    if (window.confirm('商品を作成しますか?')) {
      try {
        const data = await createProduct()

        navigate(`/admin/product/${data.product._id}`)
      } catch (err) {
        alert(getError(err))
      }
    }
  }

  const deleteHandler = async (product: NonNullable<typeof responseProducts>['products'][number]) => {
    if (window.confirm('商品を削除しますか?')) {
      try {
        const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
        await apiClients['/api/admin/products/:id'].DELETE.client({
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: { id: product._id }
        })
        alert('商品を削除しました')
        window.location.reload()
      } catch (err) {
        alert(getError(error))
      }
    }
  }

  return (
    <div>
      <Helmet>
        <title>Visnu EC - 商品管理ページ</title>
      </Helmet>
      <Container maxWidth="md" sx={{ py: 5 }}>
        <h1>商品管理</h1>
        <Stack gap={3}>
          <Box>
            <Button type="button" variant="contained" onClick={createHandler}>
              商品作成
            </Button>
          </Box>
          {isLoading ? (
            <LoadingBox />
          ) : error ? (
            <MessageBox variant="danger">{getError(error)}</MessageBox>
          ) : (
            <Stack gap={2}>
              <Typography>商品数： {responseProducts?.countProducts}</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>画像</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>商品名</TableCell>
                    <TableCell>価格</TableCell>
                    <TableCell>カテゴリ</TableCell>
                    <TableCell>在庫数</TableCell>
                    <TableCell>操作</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {responseProducts.products.map((product) => (
                    <TableRow key={product._id}>
                      <TableCell>
                        <Box
                          component={'img'}
                          src={getImageUrl(product.images[0])}
                          alt={product.name}
                          loading="lazy"
                          sx={{
                            width: 80,
                            height: 100,
                            objectFit: 'cover',
                            background: 'white',
                            borderRadius: 10,
                            overflow: 'hidden'
                          }}
                        />
                      </TableCell>
                      <TableCell>{product._id}</TableCell>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>
                        {product.prices && product.prices.oneTime.unitAmount
                          ? '￥' + product.prices.oneTime.unitAmount
                          : '未定'}
                      </TableCell>
                      <TableCell>{product.category}</TableCell>
                      <TableCell>{product.countInStock}</TableCell>
                      <TableCell>
                        <Button type="button" onClick={() => navigate(`/admin/product/${product._id}`)}>
                          編集
                        </Button>
                        &nbsp;
                        <Button type="button" onClick={() => deleteHandler(product)}>
                          削除
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Stack direction={'row'} gap={2} justifyContent={'center'}>
                <Pagination
                  page={page}
                  count={responseProducts?.pages}
                  renderItem={(item) => (
                    <PaginationItem component={Link} to={`/admin/products?page=${item.page}`} {...item} />
                  )}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Container>
    </div>
  )
}
