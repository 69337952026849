import { Form, ListGroup } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import LoadingBox from '../../components/LoadingBox'
import MessageBox from '../../components/MessageBox'
import {
  useGetOrderDetailsAdminQuery,
  useUpdateOrderDelivery,
  useUpdatePaymentStatustoPaid,
  useUpdatePaymentStatustoUnpaid
} from '../../hooks/orderHooks'
import { Box, Button, Stack, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import { formatPrice, getError, getImageUrl } from '../../utils'
import { useState } from 'react'
import { SectionTitle } from '../../components/typography'
import { ProductCategoryChip } from '../../components/common'
import { InputTitle } from '../../components/form'

export default function OrderAdminPage() {
  const [trackingNumber, setTrackingNumber] = useState('')
  const [shippingCompany, setShippingCompany] = useState('')

  const params = useParams()
  const { id: orderId } = params

  const { data: order, isLoading } = useGetOrderDetailsAdminQuery(orderId)

  const { mutateAsync: deliverOrder, isPending: loadingDeliver } = useUpdateOrderDelivery()

  async function deliverOrderHandler() {
    try {
      await deliverOrder({
        orderId: orderId,
        trackingNumber: trackingNumber,
        shippingCompany: shippingCompany
      })

      alert('Order is delivered')
      window.location.reload()
    } catch (err) {
      alert(getError(err))
    }
  }

  const { mutateAsync: changePaymentStatusUnpaid, isPending: loadingPaymentChangeUnpaid } =
    useUpdatePaymentStatustoUnpaid()

  async function changePaymentStatusUnpaidHandler() {
    try {
      await changePaymentStatusUnpaid(orderId)

      alert('未払いに変更')
      window.location.reload()
    } catch (err) {
      alert(getError(err))
    }
  }

  const { mutateAsync: changePaymentStatusPaid, isPending: loadingPaymentChangePaid } = useUpdatePaymentStatustoPaid()

  async function changePaymentStatusPaidHandler() {
    try {
      await changePaymentStatusPaid(orderId)

      alert('支払い成功に変更')
      window.location.reload()
    } catch (err) {
      alert(getError(err))
    }
  }

  return isLoading ? (
    <LoadingBox></LoadingBox>
  ) : !order ? (
    <MessageBox variant="danger">注文見つかりません</MessageBox>
  ) : (
    <div style={{ margin: 'auto', width: '50%' }}>
      <h1 className="my-3">注文： {order._id}</h1>
      <h3 className="my-3">Stripe Id： {order.stripeId}</h3>
      <Stack sx={{ width: '100%' }} gap={3}>
        <SectionTitle>ご注文情報</SectionTitle>
        <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
          <InputTitle width={200}>お届け先</InputTitle>
          <Stack gap={0.5}>
            <Typography fontSize={16} fontWeight={600}>
              {order.shippingAddress?.fullName}
            </Typography>
            <Stack pl={1}>
              <Typography fontSize={12} fontWeight={600}>
                {order.shippingAddress?.postalCode}
              </Typography>
              <Typography fontSize={12} fontWeight={600}>
                {order.shippingAddress?.address}
              </Typography>
            </Stack>
          </Stack>
          {order.isDelivered && order.deliveredAt ? (
            <MessageBox variant="success">
              配送日：{' '}
              {new Date(order.deliveredAt).toLocaleDateString('ja-JP', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
              <br />
              追跡番号： {order.shippingCompany} : {order.trackingNumber}
            </MessageBox>
          ) : (
            <MessageBox variant="warning">未発送</MessageBox>
          )}
          {!order.isDelivered && (
            <Form onSubmit={deliverOrderHandler}>
              <Form.Group className="mb-3" controlId="slug">
                <Form.Label>発送会社</Form.Label>
                <Form.Select onChange={(e) => setShippingCompany(e.target.value)} required>
                  <option>発送会社を選択する</option>
                  <option value="ヤマト">ヤマト</option>
                  <option value="佐川">佐川</option>
                  <option value="西濃">西濃</option>
                  <option value="その他">その他</option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>追跡番号</Form.Label>
                <Form.Control onChange={(e) => setTrackingNumber(e.target.value)} required />
              </Form.Group>
              <div className="d-grid oneThirdWidth">
                <Button
                  style={{
                    backgroundColor: 'rgb(25, 118, 210)',
                    color: 'white'
                  }}
                  disabled={loadingDeliver}
                  type="submit"
                >
                  注文郵送
                </Button>
                {loadingDeliver && <LoadingBox></LoadingBox>}
              </div>
            </Form>
          )}
        </Stack>
      </Stack>

      <Stack sx={{ width: '100%' }} gap={3}>
        <SectionTitle>支払い</SectionTitle>
        <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
          <InputTitle width={200}>方法:</InputTitle>
          <Stack gap={0.5}>{order.paymentMethod}</Stack>

          {order.isPaid && order.paidAt ? (
            <MessageBox variant="success">
              支払日：{' '}
              {new Date(order.paidAt).toLocaleDateString('ja-JP', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </MessageBox>
          ) : (
            <MessageBox variant="warning">未払い</MessageBox>
          )}
          {order.isPaid && (
            <ListGroup.Item>
              {loadingPaymentChangeUnpaid && <LoadingBox></LoadingBox>}
              <div className="d-grid oneThirdWidth">
                <Button
                  style={{
                    backgroundColor: 'rgb(25, 118, 210)',
                    color: 'white'
                  }}
                  type="button"
                  onClick={changePaymentStatusUnpaidHandler}
                >
                  未払いに変更
                </Button>
              </div>
            </ListGroup.Item>
          )}
          {!order.isPaid && (
            <ListGroup.Item>
              {loadingPaymentChangePaid && <LoadingBox></LoadingBox>}
              <div className="d-grid oneThirdWidth">
                <Button
                  style={{
                    backgroundColor: 'rgb(25, 118, 210)',
                    color: 'white'
                  }}
                  type="button"
                  onClick={changePaymentStatusPaidHandler}
                >
                  支払い成功に変更
                </Button>
              </div>
            </ListGroup.Item>
          )}
        </Stack>
      </Stack>

      <Stack sx={{ width: '100%' }} gap={3}>
        <SectionTitle>ご注文商品</SectionTitle>
        <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
          <Table>
            <TableBody>
              {order.orderItems.map((item) => (
                <TableRow>
                  <TableCell>
                    <Box
                      component="img"
                      src={getImageUrl(item.image)}
                      alt={item.name}
                      width={80}
                      height={100}
                      sx={{ borderRadius: '10px', objectFit: 'cover' }}
                    />
                  </TableCell>
                  <TableCell>
                    <Stack gap={1}>
                      <ProductCategoryChip>食品</ProductCategoryChip>
                      {item.name}
                    </Stack>
                  </TableCell>
                  <TableCell>{formatPrice(item.price)}</TableCell>
                  <TableCell>数量：{item.quantity}</TableCell>
                  <TableCell>{formatPrice(item.price * item.quantity)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Stack>
      </Stack>

      <Stack sx={{ width: '100%' }} gap={3}>
        <SectionTitle>ご注文商品</SectionTitle>
        <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
          <Table
            sx={{
              td: {
                borderBottom: 'none',
                fontSize: 24,
                fontWeight: 600,
                px: 0,
                py: 1
              },
              'td:first-child': {
                width: '100%',
                fontSize: 12,
                fontWeight: 600,
                color: '#868686',
                whiteSpace: 'nowrap',
                pr: 1
              },
              'td:last-child': {
                pl: 1
              },
              'tr:last-child td': {
                borderTop: '3px solid #B1B1B1'
              }
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell align="right">小計</TableCell>
                <TableCell align="right">{formatPrice(order.totalPrice)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">配送料</TableCell>
                <TableCell align="right">{formatPrice(0)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="right">合計</TableCell>
                <TableCell align="right">{formatPrice(order.totalPrice)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Stack>
      </Stack>
    </div>
  )
}
