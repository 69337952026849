import {
  Box,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  styled
} from '@mui/material'

export const CheckoutStepper = (props: { currentStep: number }) => {
  const steps = ['ショッピングカート', '各種情報入力', '完了']

  return (
    <Stepper alternativeLabel activeStep={props.currentStep} connector={<StepperConnector />} sx={{ width: '100%' }}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={StepperStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

const StepperConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50%)',
    right: 'calc(50%)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#B1B1B1'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#B1B1B1'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#B1B1B1',
    borderTopWidth: 3,
    borderRadius: 1
  }
}))

function StepperStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <StepperStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '4px',
            border: '3px solid #B1B1B1',
            transform: 'rotate(45deg)',
            background: 'white',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              width: 15,
              height: 15,
              background: '#3C3C3C',
              borderRadius: '5px'
            }}
          />
        </Box>
      ) : active ? (
        <Box
          sx={{
            width: 24,
            height: 24,
            borderRadius: '4px',
            border: '3px solid #FF7C7C',
            transform: 'rotate(45deg)',
            background: 'white',
            zIndex: 1
          }}
        />
      ) : (
        <Box
          sx={{
            width: 16,
            height: 16,
            background: '#B1B1B1',
            borderRadius: '3px',
            transform: 'rotate(45deg)',
            zIndex: 1
          }}
        />
      )}
    </StepperStepIconRoot>
  )
}

const StepperStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4'
  })
}))
