import { useAuth0 } from '@auth0/auth0-react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Box,
  Button,
  Container,
  Divider,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { getAPIAccessToken } from '../../lib/auth0'
import { getMineCart } from '../../lib/apiClient'
import { Link } from 'react-router-dom'
import { Store } from '../../Store'
import { RoundedButton } from '../../components/button'
import { ProductCategoryChip } from '../../components/common'
import { CheckoutStepper } from '../../components/stepper'
import { PageTitle } from '../../components/typography'
import { useSnackbar } from '../../context/snackbarContext'
import { useAddCartMutation, useRemoveCartItemMutation } from '../../hooks/cartHooks'
import { CartItem } from '../../types/Cart'
import { formatPrice, getError, getImageUrl } from '../../utils'

type Cart = Awaited<ReturnType<typeof getMineCart>>['cart']

/**
 * カートページ
 *
 */

type APIResponse = Awaited<ReturnType<typeof getMineCart>>

export default function CartPage() {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const snackbar = useSnackbar()
  const {
    state: { userInfo, cart },
    dispatch
  } = useContext(Store)
  const { mutateAsync: addCart } = useAddCartMutation()

  const [cartData, setCartData] = useState<Cart>()
  const [totalPrice, setTotalPrice] = useState(0)
  const [ProductsInfo, setProductsInfo] = useState<APIResponse['productsInfo']>()

  useEffect(() => {
    if (userInfo) {
      const response = async () => {
        const token = await getAPIAccessToken(getAccessTokenSilently, getAccessTokenWithPopup)
        const body = await getMineCart(token)
        setProductsInfo(body.productsInfo)
        setTotalPrice(body.totalPrice)
        setCartData(body.cart)
      }

      response()
    } else {
      if (cart) {
        setCartData({
          cartItems: cart.cartItems
        })
      }
    }
  }, [cart, getAccessTokenSilently, getAccessTokenWithPopup, userInfo])

  //カートから商品を削除
  const { mutateAsync: removeFromCart } = useRemoveCartItemMutation()

  const handleRemoveItem = useCallback(
    async (item: CartItem) => {
      dispatch({ type: 'CART_REMOVE_ITEM', payload: item })

      //DBに保存
      if (userInfo) {
        try {
          await removeFromCart({
            id: item.id
          })
          snackbar.showSnackbar('カートから削除しました', 'success')
        } catch (err) {
          snackbar.showSnackbar(getError(err), 'error')
        }
      } else {
        const localCart = localStorage.getItem('cartItems')
        if (localCart) {
          const index = JSON.parse(localCart).findIndex((obj: { id: string }) => obj.id === item.id)
          const deletedCart: CartItem[] = [
            ...JSON.parse(localCart).slice(0, index),
            ...JSON.parse(localCart).slice(index + 1)
          ]

          localStorage.setItem('cartItems', JSON.stringify(deletedCart))
          snackbar.showSnackbar('カートから削除しました', 'success')
        }
      }
    },
    [dispatch, removeFromCart, snackbar, userInfo]
  )

  const handleChangeQuantity = useCallback(
    async (item: CartItem, quantity: number) => {
      dispatch({
        type: 'CART_ADD_ITEM',
        payload: { ...item, quantity }
      })
      //DBに保存
      if (userInfo) {
        try {
          await addCart({
            cartItem: {
              id: item.id,
              quantity: quantity
            },
            quantity: quantity
          })
          snackbar.showSnackbar('商品数量を変更しました', 'success')
        } catch (err) {
          snackbar.showSnackbar(getError(err), 'error')
        }
      }
    },
    [addCart, dispatch, snackbar, userInfo]
  )

  return (
    <>
      <Helmet>
        <title>Visnu EC - カート</title>
      </Helmet>
      <Container maxWidth="md" sx={{ pt: '200px', pb: '130px' }}>
        <Stack gap={6} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <PageTitle title="Shopping Cart" subtitle="ショッピングカート" />
          <CheckoutStepper currentStep={0} />
          <Stack gap={2} sx={{ width: '100%' }}>
            <Table>
              <TableHead
                sx={{
                  th: {
                    p: 1,
                    fontSize: 16,
                    fontWeight: 500,
                    color: '#868686',
                    background: '#FDF1E0',
                    border: 'none'
                  }
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      borderRadius: '8px 0 0 8px'
                    }}
                  />
                  <TableCell>商品</TableCell>
                  <TableCell>価格</TableCell>
                  <TableCell>数量</TableCell>
                  <TableCell sx={{ borderRadius: '0 8px 8px 0' }}>小計</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cartData?.cartItems.length ? (
                  cartData.cartItems.map((item: CartItem) => (
                    <TableRow key={item.id} sx={{ td: { height: 140 } }}>
                      <TableCell>
                        <Box
                          component={Link}
                          to={`/product/${
                            ProductsInfo?.find((element) => {
                              if (element.id.toString() === item.id) {
                                return item
                              }
                            })?.slug
                          }`}
                        >
                          <Box
                            component={'img'}
                            src={getImageUrl(
                              ProductsInfo?.find((element) => {
                                if (element.id.toString() === item.id) {
                                  return item
                                }
                              })?.image
                            )}
                            alt={
                              ProductsInfo?.find((element) => {
                                if (element.id.toString() === item.id) {
                                  return item
                                }
                              })?.name
                            }
                            sx={{
                              height: 100,
                              maxHeight: 100,
                              width: 80,
                              maxWidth: 80,
                              objectFit: 'cover',
                              borderRadius: '10px',
                              overflow: 'hidden'
                            }}
                          />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Stack height={'100%'} justifyContent={'space-between'} alignItems={'flex-start'} gap={1}>
                          <ProductCategoryChip>食品</ProductCategoryChip>
                          <Typography
                            component={Link}
                            to={`/product/${
                              ProductsInfo?.find((element) => {
                                if (element.id.toString() === item.id) {
                                  return item
                                }
                              })?.slug
                            }`}
                            sx={{
                              fontSize: 16,
                              fontWeight: 600,
                              flexGrow: 1,
                              textDecoration: 'none',
                              ':hover': {
                                textDecoration: 'underline'
                              }
                            }}
                          >
                            {
                              ProductsInfo?.find((element) => {
                                if (element.id.toString() === item.id) {
                                  return item
                                }
                              })?.name
                            }
                          </Typography>
                          <Button
                            variant="text"
                            size="small"
                            sx={{ color: '#952D71' }}
                            onClick={() => handleRemoveItem(item)}
                          >
                            {'>カートから削除'}
                          </Button>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={16} fontWeight={600}>
                          {formatPrice(
                            ProductsInfo?.find((element) => {
                              if (element.id.toString() === item.id) {
                                return item
                              }
                            })?.price as number
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Select
                          defaultValue={item.quantity}
                          value={item.quantity}
                          onChange={(e) => {
                            handleChangeQuantity(item, Number(e.target.value))
                          }}
                          size="small"
                          sx={{ minWidth: 150, background: '#FFFFFF' }}
                          IconComponent={(props) => (
                            <Box
                              {...props}
                              sx={{
                                width: '15px',
                                height: '15px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '15px',
                                background: '#B1B1B1',
                                right: '10px'
                              }}
                            >
                              <ExpandMoreIcon
                                sx={{
                                  color: '#FFFFFF',
                                  fontSize: '18px'
                                }}
                              />
                            </Box>
                          )}
                        >
                          {Array.apply(0, Array(20)).map(function (_x, i) {
                            return (
                              <MenuItem key={i + 1} value={i + 1}>
                                {i + 1}
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </TableCell>
                      <TableCell>
                        <Typography fontSize={16} fontWeight={600}>
                          {formatPrice(
                            (ProductsInfo?.find((element) => {
                              if (element.id.toString() === item.id) {
                                return item
                              }
                            })?.price as number) * item.quantity
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={100} align="center">
                      カートに商品がありません
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Stack sx={{ width: '100%', alignItems: 'flex-end' }}>
              <Stack gap={1}>
                <Divider sx={{ borderBottomWidth: '3px' }} />
                <Stack direction={'row'} gap={2} alignItems={'center'}>
                  <Typography component={'span'} fontSize={12} fontWeight={600} color="#868686" pl={3}>
                    合計
                  </Typography>
                  <Typography fontSize={24} fontWeight={600}>
                    {formatPrice(totalPrice || 0)}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction={'row'} sx={{ width: '100%', justifyContent: 'space-between' }}>
            <RoundedButton color="gray" component={Link} to="/">
              商品選択へ戻る
            </RoundedButton>
            <Stack alignItems={'flex-end'} gap="20px">
              <RoundedButton
                component={Link}
                to="/order-confirmation"
                disabled={!cartData?.cartItems.length}
                color="primaryGradient"
              >
                購入手続きへ
              </RoundedButton>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </>
  )
}
