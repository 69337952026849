import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Badge,
  Box,
  ButtonBase,
  Container,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  styled
} from '@mui/material'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import Form from 'react-bootstrap/Form'
import { Helmet } from 'react-helmet-async'
import { useNavigate, useParams } from 'react-router-dom'
import LoadingBox from '../../components/LoadingBox'
import MessageBox from '../../components/MessageBox'
import { useGetProductDetailsByIdQuery, usePostProductDetailsByIdQuery } from '../../hooks/productHooks'
import { usePostDeleteImageQuery, usePostUploadImageQuery } from '../../hooks/uploadHooks'
import { getError, getImageUrl } from '../../utils'
import { useSnackbar } from '../../context/snackbarContext'
import { useGetAllCategoriesUnpaged } from '../../hooks/categoriesHooks'
import { SectionTitle } from '../../components/typography'
import { prices, stripeunit, metadata } from '../../types/Product'
import { InputTitle } from '../../components/form'
import { RoundedButton } from '../../components/button'

export default function ProductEditPage() {
  const navigate = useNavigate()
  const params = useParams() // /product/:id
  const { id: productId } = params
  const snackbar = useSnackbar()

  const [name, setName] = useState('')
  const [slug, setSlug] = useState('')
  const [oneTimePrice, setOneTimePrice] = useState(0)
  const [oneTimeId, setOneTimeId] = useState('')
  const [monthlyPrice, setMonthlyPrice] = useState(0)
  const [monthlyId, setMonthlyId] = useState('')

  const [microCmsId, setMicroCmsId] = useState('')
  const [stripeProductId, setStripeProductId] = useState('')

  const [images, setImages] = useState<string[]>([])
  const [category, setCategory] = useState('')
  const [countInStock, setCountInStock] = useState(0)
  const [brand, setBrand] = useState('')
  const [description, setDescription] = useState('')
  const [categoryList, setCategoryList] = useState<string[]>([])

  const imageInputRefs = useRef<(HTMLInputElement | null)[]>([])
  const addImageInputRef = useRef<HTMLInputElement | null>(null)

  const [nameError, setNameError] = useState('')
  const [slugError, setSlugError] = useState('')
  const [microCmsIdError, setMicroCmsIdError] = useState('')
  const [stripeProductIdError, setStripeProductIdError] = useState('')
  const [oneTimeIdError, setOneTimeIdError] = useState('')
  const [monthlyIdError, setMonthlyIdError] = useState('')
  const [brandError, setBrandError] = useState('')

  const [formIsValid, setFormIsValid] = useState(true)

  const { mutateAsync: updateProduct } = usePostProductDetailsByIdQuery(productId)

  const { data: product, isLoading, error } = useGetProductDetailsByIdQuery(productId)

  const { data: responseCategories, isPending: loadingCategory } = useGetAllCategoriesUnpaged()

  useEffect(() => {
    if (product) {
      setName(product.name)
      setSlug(product.slug)
      if (product.prices) {
        setOneTimePrice(product.prices.oneTime.unitAmount)
        setOneTimeId(product.prices.oneTime.stripePriceId)
        setMonthlyPrice(product.prices.monthly.unitAmount)
        setMonthlyId(product.prices.monthly.stripePriceId)
      }
      if (product.metadata) {
        setMicroCmsId(product.metadata.microCmsId)
        setStripeProductId(product.metadata.stripeProductId)
      }
      setImages(product.images)
      setCategory(product.category)
      setCountInStock(product.countInStock)
      setBrand(product.brand)
      setDescription(product.description)
    }
    if (responseCategories) {
      setCategoryList(responseCategories.category)
    }
  }, [product, responseCategories])

  useEffect(() => {
    imageInputRefs.current = imageInputRefs.current.slice(0, images.length)
  }, [images])

  const handleNameValidation = (nameInput: string, type: string) => {
    let formIsValid = true
    if (type === 'name') {
      setName(nameInput)
    } else if (type === 'brand') {
      setBrand(nameInput)
    }
    //Name
    if (!nameInput) {
      formIsValid = false
      if (type === 'name') {
        setNameError('*未入力です')
      } else if (type === 'brand') {
        setBrandError('*未入力です')
      }
    } else if (nameInput.length > 50) {
      formIsValid = false
      if (type === 'name') {
        setNameError('*50桁以上は入力できません')
      } else if (type === 'brand') {
        setBrandError('*50桁以上は入力できません')
      }
    }

    //name 全角確認
    for (let i = 0; i < nameInput.length; i++) {
      const character = nameInput.charAt(i)
      if (!character.match(/^[a-zA-Z\s0-9ぁ-んァ-ヶー一-龥々（）]+$/)) {
        formIsValid = false
        if (type === 'name') {
          setNameError('*記号文字は入力できません')
        } else if (type === 'brand') {
          setBrandError('*記号文字は入力できません')
        }
      }
    }

    if (formIsValid) {
      if (type === 'name') {
        setNameError('')
      } else if (type === 'brand') {
        setBrandError('')
      }
    }

    setFormIsValid(formIsValid)
    return formIsValid
  }

  const handleIdAndNameValidation = (nameInput: string, type: string) => {
    let formIsValid = true
    if (type === 'slug') {
      setSlug(nameInput)
    } else if (type === 'microCmsId') {
      setMicroCmsId(nameInput)
    } else if (type === 'stripeProductId') {
      setStripeProductId(nameInput)
    } else if (type === 'oneTimeId') {
      setOneTimeId(nameInput)
    } else if (type === 'monthlyId') {
      setMonthlyId(nameInput)
    }

    //Name
    if (!nameInput) {
      formIsValid = false
      if (type === 'slug') {
        setSlugError('*未入力です')
      } else if (type === 'microCmsId') {
        setMicroCmsIdError('*未入力です')
      } else if (type === 'stripeProductId') {
        setStripeProductIdError('*未入力です')
      } else if (type === 'oneTimeId') {
        setOneTimeIdError('*未入力です')
      } else if (type === 'monthlyId') {
        setMonthlyIdError('*未入力です')
      }
    } else if (nameInput.length > 50) {
      formIsValid = false
      if (type === 'slug') {
        setSlugError('*50桁以上は入力できません')
      } else if (type === 'microCmsId') {
        setMicroCmsIdError('*50桁以上は入力できません')
      } else if (type === 'stripeProductId') {
        setStripeProductIdError('*50桁以上は入力できません')
      } else if (type === 'oneTimeId') {
        setOneTimeIdError('*50桁以上は入力できません')
      } else if (type === 'monthlyId') {
        setMonthlyIdError('*50桁以上は入力できません')
      }
    }

    //name 全角確認
    for (let i = 0; i < nameInput.length; i++) {
      const character = nameInput.charAt(i)
      if (!character.match(/^[a-zA-Z\s0-9-_]+$/)) {
        formIsValid = false
        if (type === 'slug') {
          setSlugError('*アルファベット、数字とハイフンのみ')
        } else if (type === 'microCmsId') {
          setMicroCmsIdError('*アルファベット、数字とハイフンのみ')
        } else if (type === 'stripeProductId') {
          setStripeProductIdError('*アルファベット、数字とハイフンのみ')
        } else if (type === 'oneTimeId') {
          setOneTimeIdError('*アルファベット、数字とハイフンのみ')
        } else if (type === 'monthlyId') {
          setMonthlyIdError('*アルファベット、数字とハイフンのみ')
        }
      }
    }

    if (formIsValid) {
      if (type === 'slug') {
        setSlugError('')
      } else if (type === 'microCmsId') {
        setMicroCmsIdError('')
      } else if (type === 'stripeProductId') {
        setStripeProductIdError('')
      } else if (type === 'oneTimeId') {
        setOneTimeIdError('')
      } else if (type === 'monthlyId') {
        setMonthlyIdError('')
      }
    }

    setFormIsValid(formIsValid)
    return formIsValid
  }

  //商品情報を更新
  const submitHandler = async (e: { preventDefault: () => void }) => {
    e.preventDefault()
    if (!formIsValid || !product?._id) {
      snackbar.showSnackbar('入力エラーがあります', 'error')
      return
    }

    const oneTime: stripeunit = {
      stripePriceId: oneTimeId,
      unitAmount: oneTimePrice
    }
    const monthly: stripeunit = {
      stripePriceId: monthlyId,
      unitAmount: monthlyPrice
    }

    const newPrices: prices = { oneTime: oneTime, monthly: monthly }

    const metadata: metadata = {
      microCmsId: microCmsId,
      stripeProductId: stripeProductId
    }

    try {
      await updateProduct({
        name: name,
        slug: slug,
        prices: newPrices,
        images: images,
        category: category,
        countInStock: Number(countInStock),
        brand: brand,
        description: description,
        metadata: metadata
      })

      snackbar.showSnackbar('商品編集しました。', 'success')
      navigate('/admin/products')
    } catch (err) {
      snackbar.showSnackbar(getError(err), 'error')
    }
  }

  const { mutateAsync: uploadFile, isPending: loadingUpdate } = usePostUploadImageQuery()

  const { mutateAsync: deleteFile } = usePostDeleteImageQuery()

  const handleChangeImage = useCallback(
    async (index: number, file: File) => {
      if (!productId) return

      try {
        const data = await uploadFile({ file, productId })
        snackbar.showSnackbar('画像をアップロードしました。', 'success')
        const newImages = [...images]
        newImages[index] = data.file
        setImages(newImages)
      } catch (err) {
        snackbar.showSnackbar(getError(err), 'error')
      }
    },
    [images, productId, snackbar, uploadFile]
  )

  const handleDeleteImage = useCallback(
    async (index: number, url: string) => {
      try {
        const { pathname } = new URL(url)
        const fileName = pathname.split('/').pop()
        if (!fileName) {
          return
        }

        await deleteFile({
          productId: productId,
          fileName: fileName
        })
        snackbar.showSnackbar('画像を削除しました。', 'success')
        const newImages = [...images]

        newImages.splice(index, 1)

        setImages(newImages)
      } catch (err) {
        snackbar.showSnackbar(getError(err), 'error')
      }
    },
    [deleteFile, images, productId, snackbar]
  )

  return (
    <>
      <Helmet>
        <title>Visnu EC - 商品編集 ${productId}</title>
      </Helmet>
      <Container maxWidth="md" sx={{ py: 5 }}>
        <h1 style={{ margin: 'auto', width: '50%' }}>商品編集</h1>
        <h2 style={{ margin: 'auto', width: '50%' }}>Id: {productId}</h2>
        {isLoading || loadingCategory ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{getError(error)}</MessageBox>
        ) : (
          <Form style={{ margin: 'auto', width: '100%' }} onSubmit={submitHandler}>
            <SectionTitle>商品名</SectionTitle>
            <br />
            <Stack direction={'row'} alignItems={'center'} gap="50px">
              <InputTitle>商品名</InputTitle>
              <StyledTextField
                size="small"
                value={name}
                sx={{
                  '&  .MuiFormHelperText-contained': {
                    margin: '0',
                    backgroundColor: '#FFFCF8'
                  }
                }}
                onChange={(e) => handleNameValidation(e.target.value, 'name')}
                inputProps={{ maxLength: 50 }}
                required
                error={nameError !== ''}
                helperText={nameError ? nameError : ' '}
              />
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap="50px">
              <InputTitle>商品物理名</InputTitle>
              <StyledTextField
                size="small"
                value={slug}
                sx={{
                  '&  .MuiFormHelperText-contained': {
                    margin: '0',
                    backgroundColor: '#FFFCF8'
                  }
                }}
                onChange={(e) => handleIdAndNameValidation(e.target.value, 'slug')}
                inputProps={{ maxLength: 50 }}
                required
                error={slugError !== ''}
                helperText={slugError ? slugError : ' '}
              />
            </Stack>
            <br />
            <SectionTitle>metadata</SectionTitle>
            <br />
            <Stack direction={'row'} alignItems={'center'} gap="50px">
              <InputTitle>microCmsId</InputTitle>
              <StyledTextField
                size="small"
                value={microCmsId}
                sx={{
                  '&  .MuiFormHelperText-contained': {
                    margin: '0',
                    backgroundColor: '#FFFCF8'
                  }
                }}
                onChange={(e) => handleIdAndNameValidation(e.target.value, 'microCmsId')}
                inputProps={{ maxLength: 50 }}
                required
                error={microCmsIdError !== ''}
                helperText={microCmsIdError ? microCmsIdError : ' '}
              />
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap="50px">
              <InputTitle>StripeProductId</InputTitle>
              <StyledTextField
                size="small"
                value={stripeProductId}
                sx={{
                  '&  .MuiFormHelperText-contained': {
                    margin: '0',
                    backgroundColor: '#FFFCF8'
                  }
                }}
                onChange={(e) => handleIdAndNameValidation(e.target.value, 'stripeProductId')}
                inputProps={{ maxLength: 50 }}
                required
                error={stripeProductIdError !== ''}
                helperText={stripeProductIdError ? stripeProductIdError : ' '}
              />
            </Stack>
            <br />
            <SectionTitle>単品価格</SectionTitle>
            <br />
            <Stack direction={'row'} alignItems={'center'} gap="50px">
              <InputTitle>stripeの価格ID</InputTitle>
              <StyledTextField
                size="small"
                value={oneTimeId}
                sx={{
                  '&  .MuiFormHelperText-contained': {
                    margin: '0',
                    backgroundColor: '#FFFCF8'
                  }
                }}
                onChange={(e) => handleIdAndNameValidation(e.target.value, 'oneTimeId')}
                inputProps={{ maxLength: 50 }}
                required
                error={oneTimeIdError !== ''}
                helperText={oneTimeIdError ? oneTimeIdError : ' '}
              />
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap="50px">
              <InputTitle>単品価格</InputTitle>
              <StyledTextField
                size="small"
                value={oneTimePrice}
                sx={{
                  '&  .MuiFormHelperText-contained': {
                    margin: '0',
                    backgroundColor: '#FFFCF8'
                  }
                }}
                type="number"
                onChange={(e) => setOneTimePrice(+e.target.value)}
                required
              />
            </Stack>
            <br />
            <SectionTitle>月額</SectionTitle>
            <br />
            <Stack direction={'row'} alignItems={'center'} gap="50px">
              <InputTitle>月額 stripeの価格ID</InputTitle>
              <StyledTextField
                size="small"
                value={monthlyId}
                sx={{
                  '&  .MuiFormHelperText-contained': {
                    margin: '0',
                    backgroundColor: '#FFFCF8'
                  }
                }}
                onChange={(e) => handleIdAndNameValidation(e.target.value, 'monthlyId')}
                inputProps={{ maxLength: 50 }}
                required
                error={monthlyIdError !== ''}
                helperText={monthlyIdError ? monthlyIdError : ' '}
              />
            </Stack>

            <Stack direction={'row'} alignItems={'center'} gap="50px">
              <InputTitle>月額</InputTitle>
              <StyledTextField
                size="small"
                value={monthlyPrice}
                sx={{
                  '&  .MuiFormHelperText-contained': {
                    margin: '0',
                    backgroundColor: '#FFFCF8'
                  }
                }}
                type="number"
                onChange={(e) => setMonthlyPrice(+e.target.value)}
                required
              />
            </Stack>
            <br />
            <SectionTitle>画像</SectionTitle>
            <Form.Group className="mb-3" controlId="imageFile">
              <Form.Label>画像</Form.Label>
              <Stack direction={'row'} gap={2}>
                {images.map((image, i) => (
                  <Fragment key={i}>
                    <Badge
                      badgeContent={
                        <Tooltip title="削除">
                          <DeleteIcon
                            sx={{
                              '&:hover': {
                                cursor: 'pointer'
                              }
                            }}
                            onClick={() => {
                              handleDeleteImage(i, image)
                            }}
                          />
                        </Tooltip>
                      }
                    >
                      <ButtonBase
                        onClick={() => {
                          imageInputRefs.current[i]?.click()
                        }}
                      >
                        <Box
                          component={'img'}
                          src={getImageUrl(image)}
                          sx={{
                            width: 100,
                            height: 100,
                            borderRadius: '5px',
                            border: '1px solid #707070'
                          }}
                        />
                      </ButtonBase>
                      <input
                        type="file"
                        name={`images[${i}]`}
                        ref={(el) => (imageInputRefs.current[i] = el)}
                        onChange={(e) => {
                          if (!e.target.files) return
                          handleChangeImage(i, e.target.files[0])
                        }}
                        style={{ display: 'none' }}
                      />
                    </Badge>
                  </Fragment>
                ))}
                <ButtonBase
                  onClick={() => {
                    addImageInputRef.current?.click()
                  }}
                >
                  <Box
                    sx={{
                      width: 100,
                      height: 100,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: '#F0F0F0',
                      borderRadius: '5px',
                      border: '1px solid #707070'
                    }}
                  >
                    <AddIcon />
                  </Box>
                </ButtonBase>
                <input
                  type="file"
                  name="addImage"
                  ref={(el) => (addImageInputRef.current = el)}
                  onChange={(e) => {
                    if (!e.target.files) return
                    handleChangeImage(images.length, e.target.files[0])
                  }}
                  style={{ display: 'none' }}
                />
              </Stack>
            </Form.Group>
            <br />
            <SectionTitle>他</SectionTitle>
            <br />

            <Stack direction={'row'} alignItems={'center'} gap="50px">
              <InputTitle>カテゴリ</InputTitle>
              <FormControl fullWidth>
                <Select
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value)
                  }}
                  required={category === 'false'}
                >
                  {categoryList.map((cat) => (
                    <MenuItem key={cat} value={cat}>
                      {cat}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <br />
            <Stack direction={'row'} alignItems={'center'} gap="50px">
              <InputTitle>在庫数</InputTitle>
              <StyledTextField
                size="small"
                value={countInStock}
                sx={{
                  '&  .MuiFormHelperText-contained': {
                    margin: '0',
                    backgroundColor: '#FFFCF8'
                  }
                }}
                type="number"
                onChange={(e) => setCountInStock(+e.target.value)}
                required
              />
            </Stack>
            <br />
            <Stack direction={'row'} alignItems={'center'} gap="50px">
              <InputTitle>ブランド</InputTitle>
              <StyledTextField
                size="small"
                value={brand}
                sx={{
                  '&  .MuiFormHelperText-contained': {
                    margin: '0',
                    backgroundColor: '#FFFCF8'
                  }
                }}
                onChange={(e) => handleNameValidation(e.target.value, 'brand')}
                inputProps={{ maxLength: 50 }}
                required
                error={brandError !== ''}
                helperText={brandError ? brandError : ' '}
              />
            </Stack>

            <Form.Group className="mb-3" controlId="description">
              <Form.Label>商品詳細</Form.Label>
              <Form.Control
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                as="textarea"
                rows={7}
                style={{ width: '100%' }}
                required
              />
            </Form.Group>
            <div className="mb-3">
              <RoundedButton onClick={submitHandler} color="orange" loading={loadingUpdate}>
                保存
              </RoundedButton>
              {loadingUpdate && <LoadingBox></LoadingBox>}
            </div>
          </Form>
        )}
      </Container>
    </>
  )
}

const StyledTextField = styled(TextField)(() => ({
  width: 350,
  background: '#FFFFFF'
}))
