import { useAuth0 } from '@auth0/auth0-react'
import ModeEditIcon from '@mui/icons-material/ModeEdit'
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown'
import {
  Alert,
  Container,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  styled
} from '@mui/material'
import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { Store } from '../../Store'
import { RectButton, RoundedButton } from '../../components/button'
import { InputTitle, RequiredInputTag } from '../../components/form'
import { CheckoutStepper } from '../../components/stepper'
import { PageTitle, SectionTitle } from '../../components/typography'
import { useSnackbar } from '../../context/snackbarContext'
import { useShippingInfo } from '../../hooks/shippingInfoHooks'
import { EditShippingInfoDialog } from '../../components/EditShippingInfoDialog'
import { useUserInfo } from '../../hooks/userInfoHooks'
import { useBcpConnectedOrgPlaces } from '../../hooks/bcpHooks'
import { useCreateCheckoutSession } from '../../hooks/stripeHooks'
import { getError } from '../../utils'
import { ApiError } from '../../types/ApiError'
import { useForm } from 'react-hook-form'

export const OrderConfirmationLoadingContext = createContext<{
  loading: boolean
  setLoading: (loading: boolean) => void
}>({
  loading: false,
  setLoading: () => {
    throw new Error('setLoading not implemented')
  }
})

export default function OrderConfirmationPage() {
  const { loginWithRedirect, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const { state, dispatch } = useContext(Store)
  const { userInfo } = state
  const snackbar = useSnackbar()

  const [dropDown, setDropdown] = useState('0')
  const [postalCode, setPostalCode] = useState('')
  const [address, setAddress] = useState('')

  const [fullName, setFullName] = useState('')
  const [fullNameKana, setFullNameKana] = useState('')
  const [phone, setPhone] = useState('')

  const [bcpConnectedOrgPlaceId, setBcpConnectedOrgPlaceId] = useState('')
  const { data: shippingInfo } = useShippingInfo(getAccessTokenSilently, getAccessTokenWithPopup)
  const [showAddAddress, setShowAddAddress] = useState(true)
  const [loading, setLoading] = useState(false)

  const orderConfirmationFormRef = useRef<HTMLFormElement>(null)

  const [openEditShippingInfoDialog, setOpenEditSShippingInfoDialog] = useState(false)

  const {
    register,
    setValue,
    getValues,
    trigger,
    formState: { errors }
  } = useForm({
    mode: 'all',
    shouldUnregister: true
  })

  const createCheckoutSession = useCreateCheckoutSession()

  useEffect(() => {
    if (!userInfo) {
      loginWithRedirect({
        appState: {
          returnTo: '/'
        },
        authorizationParams: {
          screen_hint: 'signup'
        }
      })
    }

    if (shippingInfo?.shippingInfo) {
      setShowAddAddress(false)
      setPostalCode(shippingInfo.shippingInfo.shippingAddress[0].postalCode)
      setAddress(shippingInfo.shippingInfo.shippingAddress[0].address)
      setFullName(shippingInfo.shippingInfo.shippingAddress[0].fullName)
      setFullNameKana(shippingInfo.shippingInfo.shippingAddress[0].fullNameKana)
      setPhone(shippingInfo.shippingInfo.shippingAddress[0].phone)

      setValue('postalCode', shippingInfo.shippingInfo.shippingAddress[0].postalCode)
      setValue('address', shippingInfo.shippingInfo.shippingAddress[0].address)
      setValue('phone', shippingInfo.shippingInfo.shippingAddress[0].phone)
      setValue('fullName', shippingInfo.shippingInfo.shippingAddress[0].fullName)
      setValue('fullNameKana', shippingInfo.shippingInfo.shippingAddress[0].fullNameKana)
    }
  }, [shippingInfo, loginWithRedirect, userInfo])

  const handleCheckoutSession = useCallback(async () => {
    await trigger(`fullName`)
    await trigger(`fullNameKana`)
    await trigger(`phone`)
    await trigger(`postalCode`)
    await trigger(`address`)

    if (Object.values(errors).length > 0) {
      snackbar.showSnackbar('不正な入力があります', 'warning')
      return
    }

    const values = getValues()

    try {
      const checkoutSessionResponse = await createCheckoutSession.mutateAsync({
        shippingAddress: {
          fullName: values.fullName,
          fullNameKana: values.fullNameKana,
          postalCode: values.postalCode,
          address: values.address,
          phone: values.phone
        },
        bcpConnectedOrgPlaceId: bcpConnectedOrgPlaceId
      })
      window.location.href = checkoutSessionResponse.returnUrl
    } catch (err) {
      alert(getError(err as ApiError))
    }
  }, [snackbar])

  //お届け先を選択で選択したら、localstorageに設定
  const handleDropDownAddress = (dropdownValue: string) => {
    if (!shippingInfo?.shippingInfo) return
    setDropdown(dropdownValue)
    shippingInfo.shippingInfo.shippingAddress.map((addressData, key) => {
      if (key.toString() === dropdownValue) {
        setPostalCode(addressData.postalCode)
        setAddress(addressData.address)
        setPhone(addressData.phone)
        setFullName(addressData.fullName)
        setFullNameKana(addressData.fullNameKana)

        setValue('postalCode', addressData.postalCode)
        setValue('address', addressData.address)
        setValue('phone', addressData.phone)
        setValue('fullName', addressData.fullName)
        setValue('fullNameKana', addressData.fullNameKana)

        const fullName = addressData.fullName
        const postalCode = addressData.postalCode
        const address = addressData.address
        const phone = addressData.phone

        dispatch({
          type: 'SAVE_SHIPPING_ADDRESS',
          payload: {
            fullName,
            postalCode,
            address,
            phone
          }
        })
      }
    })
  }

  const handleEditShippingInfo = useCallback(() => {
    setOpenEditSShippingInfoDialog(true)
  }, [])

  const handleCloseEditShippingInfoDialog = useCallback(() => {
    setOpenEditSShippingInfoDialog(false)
  }, [])

  return (
    <OrderConfirmationLoadingContext.Provider value={{ loading, setLoading }}>
      <Helmet>
        <title>Visnu EC - ご注文内容確認</title>
      </Helmet>
      <Container maxWidth="md" sx={{ position: 'relative', pt: '200px' }}>
        <Stack gap={6}>
          <PageTitle title="Order Confirmation" subtitle="ご注文内容確認" />
          <CheckoutStepper currentStep={1} />
        </Stack>
      </Container>

      <Container maxWidth="md" sx={{ position: 'relative', pt: 6, pb: '130px' }}>
        <Stack
          ref={orderConfirmationFormRef}
          component={'form'}
          id="order-confirmation-form"
          gap={6}
          sx={{ justifyContent: 'center', alignItems: 'center' }}
        >
          <Stack sx={{ width: '100%' }} gap={3}>
            <SectionTitle>商品のお届け先</SectionTitle>
            <Stack px="30px" gap="5px">
              {shippingInfo?.shippingInfo ? (
                <>
                  <Stack direction={'row'} alignItems={'center'} gap="50px">
                    <InputTitle>お届け先の住所を選択</InputTitle>
                    <RequiredInputTag />
                    <FormControl fullWidth>
                      <Select
                        value={dropDown.toString()}
                        onChange={(e) => {
                          handleDropDownAddress(e.target.value)
                        }}
                        required={address === 'false'}
                      >
                        {shippingInfo.shippingInfo.shippingAddress.map((addressInfo, key) => (
                          <MenuItem value={key.toString()}>{addressInfo.address}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Stack>
                  <br />
                  <Stack direction={'row'} alignItems={'center'} gap="50px">
                    <InputTitle>お届け先の住所</InputTitle>
                    <Stack
                      sx={{
                        width: 70,
                        minWidth: 70,
                        height: 20,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    ></Stack>
                    <Typography fontSize={16} fontWeight={600}>
                      〒{postalCode}
                      <br />
                      {address}
                    </Typography>
                  </Stack>
                  <Stack direction={'row'} alignItems={'center'} gap="50px">
                    <InputTitle></InputTitle>
                    <Stack
                      sx={{
                        width: 70,
                        minWidth: 70,
                        height: 20,
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    ></Stack>
                    <Stack sx={{ width: '100%' }}>
                      <Divider sx={{ bgcolor: '#B1B1B1' }} />
                    </Stack>
                  </Stack>
                  <RectButton
                    variant="contained"
                    sx={{
                      width: 200,
                      background: '#236CB5',
                      float: 'right',
                      marginLeft: 'auto',
                      marginRight: 0
                    }}
                    startIcon={<ModeEditIcon />}
                    onClick={handleEditShippingInfo}
                  >
                    お届け先住所を編集
                  </RectButton>
                  <br />
                </>
              ) : (
                <div />
              )}
              <Stack direction={'row'} alignItems={'center'} gap="50px">
                <InputTitle>氏名</InputTitle>
                <RequiredInputTag />
                <StyledTextField
                  size="small"
                  sx={{
                    '&  .MuiFormHelperText-contained': {
                      margin: '0',
                      backgroundColor: '#FFFCF8'
                    }
                  }}
                  id={'fullName'}
                  defaultValue={fullName}
                  {...register(`fullName`, {
                    required: '氏名は未入力です',
                    maxLength: { value: 30, message: '氏名は30桁以上は入力できません' },
                    pattern: {
                      value: /^[a-zA-Z\s0-9ぁ-んァ-ヶー一-龥々]+$/,
                      message: '記号文字は入力できません'
                    }
                  })}
                  inputProps={{ maxLength: 30 }}
                  error={Boolean(errors.fullName?.message)}
                  helperText={errors.fullName?.message?.toString()}
                />
              </Stack>

              <Stack direction={'row'} alignItems={'center'} gap="50px">
                <InputTitle>フリガナ</InputTitle>
                <RequiredInputTag />
                <StyledTextField
                  size="small"
                  sx={{
                    '&  .MuiFormHelperText-contained': {
                      margin: '0',
                      backgroundColor: '#FFFCF8'
                    }
                  }}
                  id={'fullNameKana'}
                  defaultValue={fullNameKana}
                  {...register(`fullNameKana`, {
                    required: 'フリガナは未入力です',
                    maxLength: { value: 30, message: 'フリガナは30桁以上は入力できません' },
                    pattern: {
                      value: /^[a-zA-Z\s0-9ぁ-んァ-ヶー一-龥々]+$/,
                      message: '記号文字は入力できません'
                    }
                  })}
                  inputProps={{ maxLength: 30 }}
                  error={Boolean(errors.fullNameKana?.message)}
                  helperText={errors.fullNameKana?.message?.toString()}
                />
              </Stack>
              <Stack direction={'row'} alignItems={'center'} gap="50px">
                <InputTitle>電話番号</InputTitle>
                <RequiredInputTag />
                <StyledTextField
                  size="small"
                  sx={{
                    '&  .MuiFormHelperText-contained': {
                      margin: '0',
                      backgroundColor: '#FFFCF8'
                    }
                  }}
                  id={'phone'}
                  defaultValue={phone}
                  {...register(`phone`, {
                    required: '電話番号は未入力です',
                    maxLength: { value: 11, message: '電話番号は11桁以上は入力できません' },
                    pattern: {
                      value:
                        /^(0[5-9]0[-(]?[0-9]{4}[-)]?[0-9]{4}|0120[-]?\d{1,3}[-]?\d{4}|050[-]?\d{4}[-]?\d{4}|0[1-9][-]?\d{1,4}[-]?\d{1,4}[-]?\d{4})*$/,
                      message: '有効な電話番号を入力してください'
                    }
                  })}
                  inputProps={{ maxLength: 11 }}
                  error={Boolean(errors.phone?.message)}
                  helperText={errors.phone?.message?.toString()}
                />
              </Stack>
            </Stack>
            <Stack px="30px" gap="14px">
              <>
                <Stack direction={'row'} alignItems={'center'} gap="50px" display={showAddAddress ? 'flex' : 'none'}>
                  <InputTitle>郵便番号</InputTitle>
                  <RequiredInputTag />
                  <StyledTextField
                    size="small"
                    sx={{
                      width: 190,
                      '&  .MuiFormHelperText-contained': {
                        margin: '0',
                        backgroundColor: '#FFFCF8',
                        marginRight: '-150px'
                      }
                    }}
                    id={'postalCode'}
                    defaultValue={postalCode}
                    {...register(`postalCode`, {
                      required: '郵便番号は未入力です',
                      maxLength: { value: 7, message: '郵便番号は7桁以上は入力できません' },
                      pattern: {
                        value: /^[0-9]{7}$/,
                        message: 'ハイフン無しの有効な郵便番号を入力してください'
                      }
                    })}
                    inputProps={{ maxLength: 7 }}
                    error={Boolean(errors.postalCode?.message)}
                    helperText={errors.postalCode?.message?.toString()}
                  />
                </Stack>
                <Stack direction={'row'} alignItems={'center'} gap="50px" display={showAddAddress ? 'flex' : 'none'}>
                  <InputTitle>住所</InputTitle>
                  <RequiredInputTag />
                  <StyledTextField
                    size="small"
                    sx={{
                      width: '100%',
                      '&  .MuiFormHelperText-contained': {
                        margin: '0',
                        backgroundColor: '#FFFCF8'
                      }
                    }}
                    id={'address'}
                    {...register(`address`, {
                      required: '住所は未入力です',
                      maxLength: { value: 50, message: '住所は50桁以上は入力できません' }
                    })}
                    inputProps={{ maxLength: 100 }}
                    error={Boolean(errors.address?.message)}
                    helperText={
                      errors.address
                        ? errors.address?.message?.toString()
                        : '※マンション名や部屋番号等がある場合はすべて入力してください'
                    }
                  />
                </Stack>
              </>
            </Stack>
          </Stack>
          <VisnuBcpSection selectedPlaceId={bcpConnectedOrgPlaceId} onChangePlaceId={setBcpConnectedOrgPlaceId} />

          <EditShippingInfoDialog
            open={openEditShippingInfoDialog}
            onClose={() => {
              handleCloseEditShippingInfoDialog()
            }}
          />
        </Stack>
        <Stack gap={6} sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Stack sx={{ width: '100%' }} gap={3}></Stack>
          <Stack direction={'row'} gap={5} justifyContent={'center'}>
            <ReturnToCartButton />
            <ConfirmOrderButton
              onClick={() => {
                handleCheckoutSession()
              }}
              loading={loading}
            />
          </Stack>
          <Stack direction={'row'} alignItems={'center'} gap="50px" px="30px">
            <Typography fontSize={16} fontWeight={600}>
              ＊口座振り込みを希望のお客様は<Link to={'/contact'}>お問合せフォーム</Link>よりお問い合わせください。
            </Typography>
          </Stack>
        </Stack>
      </Container>
    </OrderConfirmationLoadingContext.Provider>
  )
}

const StyledTextField = styled(TextField)(() => ({
  width: 350,
  background: '#FFFFFF'
}))

const ReturnToCartButton = () => {
  return (
    <RoundedButton
      component={Link}
      to="/cart"
      color="gray"
      startIcon={
        <ExpandCircleDownIcon
          sx={{
            transform: 'rotate(90deg)',
            color: '#B1B1B1',
            fontSize: '16px !important'
          }}
        />
      }
    >
      カートへ戻る
    </RoundedButton>
  )
}

const ConfirmOrderButton = ({ onClick, loading }: { onClick: () => void; loading?: boolean }) => {
  return (
    <RoundedButton
      type="submit"
      onClick={onClick}
      color="orange"
      endIcon={
        <ExpandCircleDownIcon
          sx={{
            transform: 'rotate(270deg)',
            fontSize: '16px !important'
          }}
        />
      }
      loading={loading}
    >
      注文確定へ
    </RoundedButton>
  )
}

const VisnuBcpSection = ({
  selectedPlaceId,
  onChangePlaceId
}: {
  selectedPlaceId: string
  onChangePlaceId: (placeId: string) => void
}) => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  // hooks
  const userInfo = useUserInfo(getAccessTokenSilently, getAccessTokenWithPopup)
  const bcpPlaces = useBcpConnectedOrgPlaces()

  // memo
  const isAccountConnected = useMemo<boolean>(
    () => Boolean(userInfo.data?.bcpConnectedOrg),
    [userInfo.data?.bcpConnectedOrg]
  )
  const isAccountConnectionError = useMemo<boolean>(
    () => Boolean(userInfo.data?.bcpConnectedOrg?.error),
    [userInfo.data?.bcpConnectedOrg?.error]
  )

  return (
    <Stack sx={{ width: '100%' }} gap={3}>
      <SectionTitle>VisnuBCP連携</SectionTitle>
      <Stack px="30px" gap="5px">
        <Stack direction={'row'} alignItems={'center'} gap="50px" height={60}>
          <InputTitle sx={{ minWidth: 250 }}>連携組織</InputTitle>
          <Typography pl={1}>
            {isAccountConnectionError ? '連携エラー' : (userInfo.data?.bcpConnectedOrg?.name ?? '未連携')}
          </Typography>
        </Stack>
        {!isAccountConnectionError ? (
          isAccountConnected ? (
            <Stack direction={'row'} alignItems={'center'} gap="50px">
              <InputTitle sx={{ minWidth: 250 }}>自動登録先の保管場所</InputTitle>
              <FormControl fullWidth>
                <Select
                  value={selectedPlaceId}
                  onChange={(e) => {
                    onChangePlaceId(e.target.value)
                  }}
                  displayEmpty
                  size="small"
                  sx={{
                    background: '#FFFFFF'
                  }}
                >
                  <MenuItem value={''}>{'登録しない'}</MenuItem>
                  {bcpPlaces.data?.places.map((place) => (
                    <MenuItem key={place._id} value={place._id}>
                      {place.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          ) : (
            <Alert severity="info">
              <Typography>
                VisnuBCPとアカウント連携をすることで、商品購入時にVisnuBCPへの在庫の自動登録が可能となります。
              </Typography>
              <Typography>
                <Link to={'/mypage'}>My Page</Link>
                から連携設定を行ってください。
              </Typography>
            </Alert>
          )
        ) : (
          <Alert severity="error">
            <Typography>アカウント連携のエラーが発生しているため、自動登録が出来ません。</Typography>
            <Typography>
              <Link to={'/mypage'}>My Page</Link>
              からご確認ください。
            </Typography>
          </Alert>
        )}
      </Stack>
    </Stack>
  )
}
