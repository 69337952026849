import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import LoadingBox from '../../components/LoadingBox'
import MessageBox from '../../components/MessageBox'
import { getError } from '../../utils'
import { useDeleteCategoriesById, useGetAllCategories, usePostCreateCategoryQuery } from '../../hooks/categoriesHooks'
import { Category } from '../../types/Category'
import {
  Box,
  Button,
  Container,
  Pagination,
  PaginationItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'

export default function CategoryListPage() {
  const navigate = useNavigate()

  const { search } = useLocation()
  const sp = new URLSearchParams(search)
  const page = (() => {
    try {
      const p = sp.get('page')
      return p ? Number(p) : 1
    } catch (e) {
      return 1
    }
  })()

  const { data: responseCategories, isLoading, error } = useGetAllCategories(page)

  const { mutateAsync: deleteCategory } = useDeleteCategoriesById()

  const deleteHandler = async (categoryObj: Category) => {
    if (window.confirm('カテゴリは削除しますか?')) {
      try {
        const { message } = await deleteCategory({
          id: categoryObj._id
        })

        alert(message)
        window.location.reload()
      } catch (err) {
        alert(getError(err))
      }
    }
  }

  const { mutateAsync: createCategory } = usePostCreateCategoryQuery()
  const createHandler = async () => {
    if (window.confirm('カテゴリを作成しますか?')) {
      try {
        const data = await createCategory({
          label: 'sample',
          jpLabel: 'sample'
        })

        navigate(`/admin/category/${data.category._id}`)
      } catch (err) {
        alert(getError(err))
      }
    }
  }
  return (
    <div>
      <Helmet>
        <title>Visnu EC - カテゴリ管理</title>
      </Helmet>
      <Container maxWidth="md" sx={{ py: 5 }}>
        <h1>カテゴリ管理</h1>
        <Stack gap={3}>
          <Box>
            <Button type="button" variant="contained" onClick={createHandler} style={{ marginLeft: '50%' }}>
              カテゴリ作成
            </Button>
          </Box>
          {isLoading ? (
            <LoadingBox></LoadingBox>
          ) : error ? (
            <MessageBox variant="danger">{getError(error)}</MessageBox>
          ) : (
            <Stack gap={2}>
              <Typography>カテゴリ数： {responseCategories?.countCategories}</Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>カテゴリ名</TableCell>
                    <TableCell>日本語名</TableCell>
                    <TableCell>操作</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {responseCategories.categories.map((Category) => (
                    <TableRow key={Category._id}>
                      <TableCell>{Category._id}</TableCell>
                      <TableCell>{Category.label}</TableCell>
                      <TableCell>{Category.jpLabel}</TableCell>
                      <TableCell>
                        <Button type="button" onClick={() => navigate(`/admin/category/${Category._id}`)}>
                          編集
                        </Button>
                        &nbsp;
                        <Button type="button" onClick={() => deleteHandler(Category)}>
                          削除
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Stack direction={'row'} gap={2} justifyContent={'center'}>
                <Pagination
                  page={page}
                  count={responseCategories?.pages}
                  renderItem={(item) => (
                    <PaginationItem component={Link} to={`/admin/categories?page=${item.page}`} {...item} />
                  )}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
      </Container>
    </div>
  )
}
