import { Stack, SxProps, Typography } from '@mui/material'
import { ReactNode } from 'react'

export const InputTitle = (props: { children?: ReactNode; sx?: SxProps; width?: number }) => {
  return (
    <Typography
      sx={props.sx}
      fontSize={16}
      fontWeight={500}
      color="#868686"
      width={props.width ?? 130}
      minWidth={props.width ?? 130}
    >
      {props.children}
    </Typography>
  )
}

export const RequiredInputTag = () => {
  return (
    <Stack
      sx={{
        width: 70,
        minWidth: 70,
        height: 20,
        background: '#952D71',
        borderRadius: '30px',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <Typography fontSize={11} color="white" fontWeight={500}>
        必須
      </Typography>
    </Stack>
  )
}
