import type { Auth0ContextInterface } from '@auth0/auth0-react'
import { auth0 } from '../constants'

type GetAccessTokenSilentlyType = Auth0ContextInterface['getAccessTokenSilently']
type GetAccessTokenWithPopupType = Auth0ContextInterface['getAccessTokenWithPopup']

// https://github.com/Visnu-Inc/visnu-cloud/blob/main/packages/client-shared/src/lib/auth0.ts
const getAPIAccessTokenBase = async (
  getAccessTokenSilently: GetAccessTokenSilentlyType,
  getAccessTokenWithPopup: GetAccessTokenWithPopupType,
  options: {
    authorizationParams: {
      audience: string
    }
  }
) => {
  try {
    return await getAccessTokenSilently({ ...options })
  } catch (e) {
    if (Object.prototype.hasOwnProperty.call(e, 'error') && (e as { error: unknown }).error === 'consent_required') {
      return getAccessTokenWithPopup({ ...options })
    }
    throw e
  }
}

type ArgsType = Parameters<typeof getAPIAccessTokenBase>

export const getAPIAccessToken = async (getAccessTokenSilently: ArgsType[0], getAccessTokenWithPopup: ArgsType[1]) => {
  return await getAPIAccessTokenBase(getAccessTokenSilently, getAccessTokenWithPopup, {
    authorizationParams: {
      audience: auth0.authorizationParams.audience
    }
  })
}
